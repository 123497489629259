import { PureComponent } from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { isBrowser, isMobile, isTablet } from 'react-device-detect';
import autoBind from 'react-autobind';

import MainPromobar from './MainPromobar';
import MobileNotification from './MobileNotification';
import withComponentName from '../../decorators/withComponentName';

import getPromobarData from '../../services/dbServices/promobarService';
import { showPromo, hidePromo } from '../../actions/promoBarActions';
import { hiddenHeaderSearchbarCheck } from '../../data/webPageData';

import StorageSvc from '../../services/StorageSvc';

import { RBI } from '../../data/clients';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

class PromoBar extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.container = null;
    this.timer = null;
    this.state = {
      data: null,
      showedBar: false,
      showedContainer: true,
    };
    this.rbi = Boolean(props.client && props.client.name === RBI.name);
  }

  async componentDidMount() {
    if (this.rbi) return;

    window.addEventListener('resize', this.resizeHandler);

    if ((isBrowser && (!isTablet || !isMobile)) || StorageSvc.getItem('mobilePopupSeen')) {
      const res = await getPromobarData();
      this.setState({
        data: res.data.promo_bar_contents[0],
      }, () => {
        this.showPromobar('desktopPromo');
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { location, statePromo, width } = this.props;

    if (this.rbi) return;

    if (prevProps.location !== location) {
      if (StorageSvc.getItem('mobilePopupSeen')) {
        this.showPromobar('desktopPromo');
      }
    }

    const path = (location || '').split('/') || [];
    const isHeaderSearchBar = hiddenHeaderSearchbarCheck[path[1]] ?? false;

    if (
      (statePromo.promobarType === 'desktopPromo' && location === '/' && width > 767)
      || (width <= 1064 && location === '/' && width > 767)
    ) {
      document.body.style.paddingTop = `${statePromo.height}px`;
    } else if ((width <= 1024 && statePromo.height && !isHeaderSearchBar) || width < 768) {
      document.body.style.paddingTop = '0';
    } else if (location === '/chatbot') {
      document.body.style.paddingTop = '0';
    } else {
      document.body.style.paddingTop = `${statePromo.height}px`;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  getContainer(el) {
    if (el) this.container = el;
  }

  async closeMobileNotification() {
    const res = await getPromobarData();
    this.setState({
      data: res.data.promo_bar_contents[0],
    }, () => {
      this.showPromobar('desktopPromo');
    });
  }

  hidePromobar(setLocalstorage = true) {
    const { piwikEnabled, hidePromo } = this.props;
    const { data } = this.state;

    if (setLocalstorage) StorageSvc.setItem('promoID', data.id);

    this.setState({
      showedBar: false,
    }, this.hideContainer);
    hidePromo(0);

    if (piwikEnabled) _paq.push(['trackEvent', 'Promo bar', 'Close bar']);
  }

  showPromobar(type) {
    this.setState({ showedContainer: false });
    const { data } = this.state;
    const { showPromo } = this.props;

    if (!data || this.rbi) return;

    if (+StorageSvc.getItem('promoID') !== data.id) {
      setTimeout(() => {
        this.setState({
          showedBar: true,
          showedContainer: true,
        }, () => {
          showPromo(this.container.clientHeight, type);
        });
      }, 300);
    } else {
      this.setState({
        showedContainer: false,
      });
    }
  }

  hideContainer() {
    setTimeout(() => this.setState({
      showedContainer: false,
    }), 1000);
  }

  resizeHandler() {
    const { showPromo, promobarType } = this.props;

    return showPromo(this.container.clientHeight, promobarType);
  }

  sendPromobarVisitAction() {
    const { piwikEnabled } = this.props;

    if (piwikEnabled) _paq.push(['trackEvent', 'Promo bar', 'Visit link']);
  }

  render() {
    const { data, showedContainer, showedBar } = this.state;

    const promoClassName = cx('promo_container', {
      showed: showedBar,
      mobile_default: !StorageSvc.getItem('mobilePopupSeen'),
    });

    return (
      <div
        className={promoClassName}
        ref={this.getContainer}
        style={{
          display: showedContainer ? 'block' : 'none',
        }}
      >
        {!this.rbi && <MobileNotification triggerMainPromo={this.closeMobileNotification} />}
        {data && (
          <MainPromobar
            {...data}
            closePromo={this.hidePromobar}
            sendPromobarAction={this.sendPromobarVisitAction}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  statePromo: state.promobar,
  promobarType: state.promobar.promobarType,
  width: state.common.width,
});

const mapDispatchToProps = (dispatch) => ({
  showPromo: (height, promobarType) => dispatch(showPromo(height, promobarType)),
  hidePromo: (promobarType) => dispatch(hidePromo(promobarType)),
});

export default withComponentName(connect(mapStateToProps, mapDispatchToProps)(PromoBar));
