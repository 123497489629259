import { useEffect, useState, memo } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';

import { toggleScrollUpState } from '../../../actions/common.actions';

import { getBrowserScrollTop } from '../../../helpers/commonHelpers';
import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const ScrollUpButton = ({ className }) => {
  const [hide, setHide] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const onScroll = () => {
      const scrollTop = getBrowserScrollTop();

      setHide(scrollTop < 150);
    };

    window.addEventListener('scroll', onScroll, { passive: true });
    onScroll();

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    dispatch(toggleScrollUpState(true));
  };

  return (
    <button
      type="button"
      aria-label="scroll-up-button"
      className={cx('scroll-up-button', className, { hide })}
      onClick={scrollUp}
    >
      <FontAwesomeIcon icon={faChevronUp} />
    </button>
  );
};

ScrollUpButton.propTypes = {
  className: PropTypes.string,
};

ScrollUpButton.defaultProps = {
  className: '',
};

export default memo(withComponentName(ScrollUpButton), (prevProps, nextProps) => (
  prevProps.className === nextProps.className
));
