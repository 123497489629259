import { useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-solid-svg-icons/faUpRightAndDownLeftFromCenter';
import { faDownLeftAndUpRightToCenter } from '@fortawesome/pro-solid-svg-icons/faDownLeftAndUpRightToCenter';
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons/faCirclePlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Preloader from '../../Preloader';
import NewTag from '../../Shared/NewTag';
import CustomHeader from '../CustomComponents/CustomHeader';
import ChatbotComponents from '../chatbot/index';
import ChatbotDemoVideo from '../ChatbotDemoVideo';

import cityFalconIcon from '../../../assets/chatbot/favicon-chatbot.svg';

import { stringToBool } from '../../../helpers/commonHelpers';

import StorageSvc from '../../../services/StorageSvc';
import useSizes from '../../../hooks/sizes';

const ChatbotPageContent = (props) => {
  const {
    expandedClassName,
    showedMainButtonClassName,
    chatbotBannerIsActiveClassName,
    hoveredClassName,
    pageViewClassName,
    homePageClassName,
    chatbotPageClassName,
    showedHeaderIconsClassName,
    promobarClassName,
    widgetViewClassName,
    cookiesClassName,
    handleOpenWidget,
    showMainText,
    permissionsInitialized,
    showPreloaderOnScrollMessages,
    isChatbotOpened,
    isChatbotPage,
    isFinpromptPages,
    handleIconsClick,
    propsToChatbot,
    contentWrapperRef,
    expanded,
    user,
    handleCloseWidget,
  } = props;

  const { width } = useSizes();

  const [hideDemo, setHideDemo] = useState(null);
  const [hiddenAfterDelay, setHiddenAfterDelay] = useState(false);

  const handleDemoButtonClick = () => {
    StorageSvc.setItem('show_demo_chatbot_status', 'false');
    setHideDemo(false);
  };

  useEffect(() => {
    const cookiesPolicyStatusCached = stringToBool(StorageSvc.getItem('show_demo_chatbot_status'));

    if (!user) {
      setHideDemo(false);
    } else {
      setHideDemo(!!cookiesPolicyStatusCached);
    }
  }, [user]);

  useEffect(() => {
    if (!hideDemo) return;

    let timeout;
    if (width < 1024 || isTablet || isMobile) {
      timeout = setTimeout(() => {
        setHiddenAfterDelay(true);
      }, 7000);
    } else {
      setHiddenAfterDelay(false);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [width, isTablet, isMobile, hideDemo]);

  const showDemoClassname = `${!hideDemo ? 'demo_video-mode' : ''}`;
  const hideDemoAfterDelayClassname = `${hiddenAfterDelay ? 'demo_video-mode--hidden' : ''}`;

  return (
    <>
      <div
        className={`widget__button-wrapper${expandedClassName}${showedMainButtonClassName}${chatbotBannerIsActiveClassName}`}
      >
        <button
          className={`widget__button-open${hoveredClassName}${pageViewClassName}${showedMainButtonClassName}${expandedClassName}${homePageClassName}`}
          type="button"
          onClick={handleOpenWidget}
        >
          <NewTag className="new-tag-chatbot" />
          {showMainText ? (
            <span className="button-open__text">AI Assistant</span>
          ) : (
            <span className="button-open__text">I'm here if anything</span>
          ) }
          {permissionsInitialized && (
          <img
            className="button-open__img"
            src={cityFalconIcon}
            alt="cityFalcon chatbot icon"
          />
          )}
        </button>
      </div>
      {showPreloaderOnScrollMessages && isChatbotOpened && !isChatbotPage && !isFinpromptPages
      && hideDemo ? (
        <>
          <div className="widget__preloader-wrapper widget__preloader-wrapper--on-scrolling-content">
            <Preloader hasBigScreenDesign loading centered />
          </div>
        </>
        ) : null}
      <div
        className={`widget__content${expandedClassName}${pageViewClassName}${widgetViewClassName}${chatbotPageClassName}
        ${showDemoClassname}`}
      >
        <CustomHeader />
        {!hideDemo && expanded ? null : (
          <>
            {expanded ? (
              <div
                className={`demo_video-btn ${hideDemoAfterDelayClassname}`}
                onClick={handleDemoButtonClick}
              >
                <FontAwesomeIcon
                  icon={faCirclePlay}
                />
                <span>
                  View Demo
                </span>
              </div>
            ) : (<></>)}
            <div
              className={`widget__header-icons${expandedClassName}${promobarClassName}${pageViewClassName}${showedHeaderIconsClassName}`}
              onClick={handleIconsClick}
            >
              {expanded ? (
                <FontAwesomeIcon
                  className={`widget__header-icon-expand${expandedClassName}`}
                  icon={faDownLeftAndUpRightToCenter}
                />
              ) : (
                <FontAwesomeIcon
                  className="widget__header-icon-expand"
                  icon={faUpRightAndDownLeftFromCenter}
                />
              )}
              <FontAwesomeIcon
                className="widget__header-icon-times"
                data-chatbot
                icon={faTimes}
              />
            </div>
          </>
        )}
        <div
          className={`widget__outer-wrapper${expandedClassName}`}
        >
          <div
            ref={contentWrapperRef}
            className={`widget__inner-wrapper${expandedClassName}`}
          >
            { !hideDemo ? (
              <ChatbotDemoVideo
                setHideDemo={setHideDemo}
                expanded={expanded}
                isChatbotOpened={isChatbotOpened}
                setShowPreloaderOnScrollMessages={props.setShowPreloaderOnScrollMessages}
                handleCloseWidget={handleCloseWidget}
              />
            ) : (
              <ChatbotComponents {...propsToChatbot} />
            )}
          </div>
          {hideDemo ? (
            <div
              className={`widget__padding-bottom${expandedClassName}${cookiesClassName}`}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ChatbotPageContent;
