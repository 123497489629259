/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-plusplus */
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  isMobileOnly, isTablet,
} from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import 'react-chatbot-kit/build/main.css';

import ChatbotBanner from './ChatbotBanner';
import ChatbotPageContent from './ChatbotPageContent';

import withComponentName from '../../decorators/withComponentName';
import withRouter from '../../decorators/withRouter';
import windowWidthDecorator from '../../decorators/windowWidthDecorator';

import * as chatbotActions from '../../actions/chatbot.actions';

import { hiddenHeaderSearchbarCheck } from '../../data/webPageData';

import useFinprompt from '../../providers/FinpromptProvider/useFinprompt';

import StorageSvc from '../../services/StorageSvc';

import './styles.css';

const ChatbotWidget = (props) => {
  const {
    windowWidth, railsContext, datePositionFinPrompt,
  } = props;
  const history = useHistory();

  const isChatbotOpened = useSelector(({ chatbot }) => chatbot.isChatbotOpened);
  const expanded = useSelector(({ chatbot }) => chatbot.expanded);
  const permissionsInitialized = useSelector(
    ({ subscriptions }) => subscriptions.permissionsInitialized,
  );
  const promobarType = useSelector(({ promobar }) => promobar.promobarType);
  const promobarHeight = useSelector(({ promobar }) => promobar.height);
  const isShowed = useSelector(({ promobar }) => promobar.isShowed);
  const cookies = useSelector(({ common }) => common.cookiesPolicyStatus);
  const loadingResponse = useSelector(({ chatbot }) => chatbot.loading);
  const isHiddenHeaderSearchbar = useSelector(({ header }) => header.isHiddenHeaderSearchbar);
  const searchBarActive = useSelector(({ searchReducers }) => searchReducers.searchBarActive);
  const storyImageOpened = useSelector(({ stories }) => stories.storyImageOpened);
  const user = useSelector((state) => state.userPreferencesReducer.user);
  const isModalOutsideDisplayed = useSelector((state) => state.chatbot.isModalOutsideDisplayed);

  const { isFinpromptPages } = useFinprompt();

  const dispatch = useDispatch();
  const actions = useMemo(() => (
    bindActionCreators({
      ...chatbotActions,
    }, dispatch)
  ), [dispatch]);

  const topValueRef = useRef(null);
  const showedBotAfterFullPage = useRef(false);
  const widgetRef = useRef(null);
  const expandedIconWasClicked = useRef(null);
  const pathnamePreviousPage = useRef(null);
  const contentWrapperRef = useRef(null);

  const [showMainText, setShowMainText] = useState(true);
  const [showAlternativeText, setShowAlternativeText] = useState(false);
  const [styles, setStyles] = useState({ top: '80px' });
  const [chatMessageContainer, setChatMessageContainer] = useState(null);
  const [chatInnerContainer, setChatInnerContainer] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [widgetType, setWidgetType] = useState('widgetBasic');
  const [showPreloaderOnScrollMessages, setShowPreloaderOnScrollMessages] = useState(true);
  const [chatbotBannerIsActive, setChatbotBannerIsActive] = useState(false);
  const [chatbotBannerAnimation, setChatbotBannerAnimation] = useState(false);

  const isWidgetTypeFullScreen = widgetType === 'widgetFullScreen';
  const isChatbotPage = history?.location?.pathname === '/chatbot';

  const isExpandedView = isChatbotPage || isFinpromptPages || expanded;

  const showChatbotBannerHomePage = (history?.location?.pathname === '/') && chatbotBannerIsActive;

  const handleBodyClassname = (method = '') => {
    if (method === 'add') document.body.classList.add('opened-chatbot-widget');
    if (method === 'remove') document.body.classList.remove('opened-chatbot-widget');
  };

  const handleClickOutside = (e) => {
    if (isChatbotPage || isWidgetTypeFullScreen) return;
    let isOpenedVideoWistiaModal = null;

    // detecting modal window wistia player video and don't close after clicking on it
    document.querySelectorAll("*[role='dialog']").forEach((item) => {
      if (item.contains(e?.target)) {
        isOpenedVideoWistiaModal = true;
      }
    });
    if (isOpenedVideoWistiaModal) return;

    if ((e?.target && widgetRef?.current && widgetRef?.current.contains(e?.target))
      || document.getElementsByClassName('widget__header-icon-expand')[0]?.contains(e?.target)) return;

    if (widgetType === 'widgetBasic' && isChatbotOpened && showedBotAfterFullPage.current) {
      showedBotAfterFullPage.current = false;
      return;
    }

    if (
      (e?.target.classList.contains('fa-xmark')
    && !e?.target.hasAttribute('data-chatbot')
      )
    || e?.target.classList[0]?.includes('cancel')) return;

    actions.toggleBot(false);
    actions.toggleExpand(false);
    actions.toogleWidgetState({ type: 'widgetBasic', state: false });
    handleBodyClassname('remove');
  };

  const handleCloseWidget = () => {
    actions.toggleBot(false);
    actions.toggleExpand(false);
    setWidgetType('widgetBasic');
    actions.toogleWidgetState({ type: 'widgetBasic', state: false });
    handleBodyClassname('remove');
  };

  const handleOpenWidget = () => {
    actions.toggleBot(true);
    handleBodyClassname('add');
    actions.toogleWidgetState({ type: 'widgetBasic', state: true });
  };

  const handleIconsClick = (e) => {
    if (document.getElementsByClassName('widget__header-icon-times')[0]?.contains(e?.target)) {
      handleCloseWidget();
      return;
    }

    if (document.getElementsByClassName('widget__header-icon-expand')[0]?.contains(e?.target)
    || isChatbotPage || isWidgetTypeFullScreen) {
      if (expanded === false) {
        expandedIconWasClicked.current = false;
        history?.push('/chatbot');
        actions.toggleExpand(true);
        actions.toggleBot(true);
        setWidgetType('widgetFullScreen');
        showedBotAfterFullPage.current = true;
        actions.toogleWidgetState({ type: 'widgetFullScreen', state: true });
        handleBodyClassname('remove');
      } else if (expanded || isChatbotPage || isWidgetTypeFullScreen) {
        expandedIconWasClicked.current = true;
        history?.push(pathnamePreviousPage.current || '/');

        actions.toggleExpand(false);
        setWidgetType('widgetBasic');
        if ((!pathnamePreviousPage.current || pathnamePreviousPage.current === '/')
          && StorageSvc.getItem('chatbot__banner')) handleOpenWidget();
      }
    }
  };

  const showDifferentTextOnHoverBotIcon = () => {
    setShowMainText(false);
    setShowAlternativeText(true);

    setTimeout(() => {
      setShowMainText(true);
      setShowAlternativeText(false);
    }, 5000);
  };

  const handleShowMoreButton = (path = '/news') => {
    if (isFinpromptPages && path.startsWith('http')) {
      window.open(path, '_blank')?.focus?.();
    } else if (path.startsWith('http')) {
      window.location = path;
    } else if ((history?.location?.pathname !== path)) {
      history?.push(path);
      window?.scrollTo(0, 0);
      handleCloseWidget();
      showDifferentTextOnHoverBotIcon();
    }
  };

  const handleWindowScroll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop
    || document.body.scrollTop || 0;

    if ((history?.location?.pathname === '/') || isChatbotPage) return;

    if (isHiddenHeaderSearchbar && (scrollPosition > 50)) {
      setHeaderHeight(0);
    } else if (isHiddenHeaderSearchbar && (scrollPosition < 50)) {
      setHeaderHeight(50 - scrollPosition);
    }
  };

  const closeChatbotBanner = () => {
    setChatbotBannerAnimation(true);
    setTimeout(() => {
      setChatbotBannerIsActive(false);
      StorageSvc.setItem('chatbot__banner-closed', true);
    }, 500);
  };

  // checking local storage, Do we need to show chatbot banner or not
  useEffect(() => {
    if (window.location.pathname !== '/') return;

    const chatbotBannerStatusCached = StorageSvc.getItem('chatbot__banner-closed');

    let timeout = null;
    if ((!chatbotBannerStatusCached || (chatbotBannerStatusCached === 'false'))
    ) {
      setChatbotBannerIsActive(true);

      timeout = setTimeout(() => {
        StorageSvc.setItem('chatbot__banner-closed', false);
      }, 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [history?.location?.pathname]);

  // prevent any page from opening from the bottom after chatbot page
  useEffect(() => {
    if (isFinpromptPages) return;

    if (!isChatbotPage
      && (document?.documentElement?.scrollTop > 0)) {
      window.scrollTo(0, 0);
    }
    if (!isChatbotPage) pathnamePreviousPage.current = history?.location?.pathname;
  }, [history?.location?.pathname, isChatbotPage]);

  // close widget window when any searchbar is active
  useEffect(() => {
    if (!isChatbotOpened) return;

    if (!isChatbotPage && (
      searchBarActive || storyImageOpened || isModalOutsideDisplayed
    )) handleCloseWidget();
  }, [isChatbotOpened, searchBarActive, storyImageOpened,
    isModalOutsideDisplayed, isChatbotPage]);

  useEffect(() => {
    if (isChatbotPage || isFinpromptPages) {
      actions.toggleBot(true);
      actions.toggleExpand(true);
      setWidgetType('widgetFullScreen');
      actions.toogleWidgetState({ type: 'widgetFullScreen', state: true });
      document.body.classList.add('chatbot-page');
      showedBotAfterFullPage.current = true;
    } else if (expandedIconWasClicked.current
      && (!showChatbotBannerHomePage)) {
      actions.toggleExpand(false);
      setWidgetType('widgetBasic');
      actions.toogleWidgetState({ type: 'widgetBasic', state: true });
      document.body.classList.remove('chatbot-page');
      document.body.classList.add('opened-chatbot-widget');
      expandedIconWasClicked.current = false;
    } else {
      handleCloseWidget();
      document.body.classList.remove('chatbot-page', 'opened-chatbot-widget');
      expandedIconWasClicked.current = false;
    }
  }, [history?.location?.pathname, showChatbotBannerHomePage]);

  useEffect(() => {
    if (!isChatbotPage) return;

    if ('scrollRestoration' in window?.history) {
      // Disable brower's auto scroll after a page refresh
      window.history.scrollRestoration = 'manual';
    }
  }, [isChatbotPage]);

  // move chatbot icon above on scroll if header is not fixed at top web-6873
  useEffect(() => {
    if (!isChatbotPage && !isFinpromptPages) {
      window.addEventListener('scroll', handleWindowScroll);

      return () => {
        window.removeEventListener('scroll', handleWindowScroll);
      };
    }
  }, [isChatbotPage, isFinpromptPages, history?.location?.pathname]);

  // handleClickOutside eventListener web-6873
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [widgetType, isChatbotOpened]);

  // make send button disabled on loading web-6873
  useEffect(() => {
    if (!chatMessageContainer) setChatMessageContainer(document.getElementsByClassName('react-chatbot-kit-chat-message-container')[0]);
    if (!chatInnerContainer) setChatInnerContainer(document.getElementsByClassName('react-chatbot-kit-chat-inner-container')[0]);
  }, [loadingResponse, isChatbotOpened]);

  // set headerHeight size web-6873
  useEffect(() => {
    const pathname = history?.location?.pathname || railsContext?.pathname || '';
    const path = pathname.split('/');
    const hiddenHeaderSearchbar = hiddenHeaderSearchbarCheck[path[1]] ?? false;

    if (hiddenHeaderSearchbar) {
      setHeaderHeight(50);
    } else {
      setHeaderHeight(80);
    }
  }, [history?.location?.pathname, railsContext?.pathname]);

  // set top Position and maxHeight for widget web-6873
  useEffect(() => {
    let top = '';
    const promobarHeightValue = windowWidth < 768 ? 0 : promobarHeight;
    const headerHeightValue = windowWidth < 768 ? 0 : headerHeight;

    if (isChatbotPage || (widgetType === 'widgetFullScreen')) {
      top = 0;
    } else {
      top = `${promobarHeightValue + headerHeight + 16}px`;
      if (windowWidth > 2800) top = `${promobarHeightValue + headerHeightValue + 38}px`;
    }

    if (!topValueRef.current) {
      topValueRef.current = top;
      setStyles({
        top: topValueRef?.current,
      });
    } else {
      if (topValueRef.current === top) return;
      if (topValueRef.current !== top) {
        topValueRef.current = top;
        setStyles({
          top: topValueRef?.current,
        });
      }
    }
  }, [widgetType, promobarHeight, history?.location?.pathname, headerHeight, windowWidth]);

  const propsToChatbot = {
    widgetType,
    handleShowMoreButton,
    contentWrapperRef,
    isChatbotPage,
    setShowPreloaderOnScrollMessages,
    datePositionFinPrompt,
  };

  // on tablet and mobile '/chatbot' page shows only, widget is hided web-6873
  if ((widgetType === 'widgetBasic' && !isChatbotPage) && (isMobileOnly || isTablet)) return null;

  if (isFinpromptPages) return null;

  const expandedClassName = isExpandedView ? ' expanded' : '';
  const cookiesClassName = cookies ? ' cookies' : '';
  const pageViewClassName = (isChatbotPage || isFinpromptPages) ? ' opened-page-view' : '';
  const widgetViewClassName = (isChatbotOpened && !showChatbotBannerHomePage && !isChatbotPage && !isFinpromptPages) ? ' opened-widget-view' : '';
  const promobarClassName = (isShowed && (promobarHeight > 0)) ? ` promobar ${promobarType}` : '';
  const hoveredClassName = showAlternativeText ? ' hovered' : '';
  const showedMainButtonClassName = !isChatbotOpened ? ' show-button' : '';
  const showedHeaderIconsClassName = isChatbotOpened ? ' show-header-icons' : '';
  const homePageClassName = (history?.location?.pathname === '/') ? ' home-page' : '';
  const chatbotPageClassName = (history?.location?.pathname === '/chatbot') ? ' chatbot-page' : '';
  const chatbotBannerIsActiveClassName = showChatbotBannerHomePage ? ' chatbotbanner' : '';
  const chatbotPageBluredClassName = !isFinpromptPages && searchBarActive && isChatbotPage ? ' blured--chatbot-page' : '';

  return (
    <>
      <div
        className={`widget__wrapper${expandedClassName}${cookiesClassName}${pageViewClassName}${widgetViewClassName}${promobarClassName}${homePageClassName}${chatbotPageBluredClassName}`}
        style={styles}
        ref={widgetRef}
      >
        <ChatbotBanner
          closeChatbotBanner={closeChatbotBanner}
          chatbotBannerIsActive={showChatbotBannerHomePage}
          chatbotBannerAnimation={chatbotBannerAnimation}
        />
        <ChatbotPageContent
          expandedClassName={expandedClassName}
          showedMainButtonClassName={showedMainButtonClassName}
          chatbotBannerIsActiveClassName={chatbotBannerIsActiveClassName}
          hoveredClassName={hoveredClassName}
          pageViewClassName={pageViewClassName}
          homePageClassName={homePageClassName}
          chatbotPageClassName={chatbotPageClassName}
          showedHeaderIconsClassName={showedHeaderIconsClassName}
          promobarClassName={promobarClassName}
          widgetViewClassName={widgetViewClassName}
          cookiesClassName={cookiesClassName}
          handleOpenWidget={handleOpenWidget}
          showMainText={showMainText}
          permissionsInitialized={permissionsInitialized}
          showPreloaderOnScrollMessages={showPreloaderOnScrollMessages}
          isChatbotOpened={isChatbotOpened}
          isChatbotPage={isChatbotPage}
          isFinpromptPages={isFinpromptPages}
          handleIconsClick={handleIconsClick}
          propsToChatbot={propsToChatbot}
          contentWrapperRef={contentWrapperRef}
          expanded={expanded}
          user={user}
          setShowPreloaderOnScrollMessages={setShowPreloaderOnScrollMessages}
          handleCloseWidget={handleCloseWidget}
        />
      </div>
    </>
  );
};

export default withComponentName(withRouter(windowWidthDecorator(ChatbotWidget)));
