// core
import classNames from 'classnames/bind';
import { isMobile, isTablet } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import {
  useEffect,
  useMemo, useRef, useState,
} from 'react';
import { bindActionCreators } from 'redux';

import * as authActions from '../../../actions/authentication.actions';

import WistiaPlayer from '../../Shared/WistiaPlayer';
import ProgressBar from '../../FinPromptProject/DemoVideoWelcomeScreen/ProgressBar';

import Styles from './styles.module.scss';

import useSizes from '../../../hooks/sizes';
import StorageSvc from '../../../services/StorageSvc';

const cx = classNames.bind(Styles);

const videoLink = 'https://cityfalcon.wistia.com/medias/ruie8y4u3u';
const videoLinkMobile = 'https://cityfalcon.wistia.com/medias/xsp6kvm441';

const ChatbotDemoVideo = ({
  setHideDemo,
  expanded,
  isChatbotOpened,
  setShowPreloaderOnScrollMessages,
  handleCloseWidget,
}) => {
  const dispatch = useDispatch();
  const actions = useMemo(() => (
    bindActionCreators({
      ...authActions,
    }, dispatch)
  ), [dispatch]);

  const user = useSelector(({ userPreferencesReducer }) => userPreferencesReducer.user);

  const { width: screenWidth } = useSizes();

  const optionsConfigVideo = {
    autoPlay: true,
    playbackRateControl: true,
    playbar: true,
    playButton: true,
    settingsControl: true,
    muted: true,
    wmode: 'transparent',
  };

  const OnTimeChangeWasAdded = useRef(null);
  const [ref, setRef] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleClick = () => {
    if (!user) {
      actions.setAuthModalOpenedFromChatbot(true);
      return;
    }
    if (window.location.pathname !== '/chatbot') {
      handleCloseWidget?.();
      window.location = '/chatbot';
    }
    StorageSvc.setItem('show_demo_chatbot_status', 'true');
    setHideDemo(true);
    setShowPreloaderOnScrollMessages(false);
  };

  const handleReplayDemoClick = () => {
    if (progress < 99) {
      handleClick();
    } else {
      ref?.wistiaApi?.container.querySelector('video')?.play();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ref?.wistiaApi) {
        ref?.wistiaApi?.bind?.('timechange', (t) => {
          setProgress((t / ref?.wistiaApi?.duration()) * 100);
        });
        OnTimeChangeWasAdded.current = true;
      }
    }, 2000);

    if (!isChatbotOpened) {
      ref?.wistiaApi?.container.querySelector('video')?.pause();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [ref, expanded, isChatbotOpened]);

  return (
    <div
      className={cx('demo', { 'demo--widget-mode': !expanded })}
    >
      {expanded ? (
        <div className={cx('demo__intro-message')}>
          Introducing our AI chatbot that can answer all your finance and business related questions
        </div>
      ) : null}
      <WistiaPlayer
        ref={(newRef) => setRef(newRef)}
        className={cx('demo__video')}
        url={isMobile || !expanded ? videoLinkMobile : videoLink}
        withoutPopover
        style={{
          width: '100%',
          maxWidth: (screenWidth >= 1024 && expanded && '65%') || undefined,
          minWidth: screenWidth >= 768 && expanded ? '768px' : undefined,
          margin: '0 auto',
          flex: '1',
          maxHeight: ((screenWidth < 1024 || isTablet || isMobile) && expanded && 'calc(100vh - 210px)') || undefined,
        }}
        controls
        config={{
          options: isMobile
            ? {
              ...optionsConfigVideo,
              playsinline: false,
            }
            : optionsConfigVideo,
        }}
      />
      <div className={cx('demo__footer')}>
        <ProgressBar
          bgcolor="rgba(57, 113, 193, .5)"
          bgContainerColor="rgba(57, 113, 193, .2)"
          completed={progress}
          handleClick={handleReplayDemoClick}
          screenWidth={screenWidth}
          textMessage={progress > 99 ? 'Replay Demo' : 'Skip Demo'}
        />
        <div
          className={cx('demo__get-started-wrapper')}
        >
          <span
            onClick={handleClick}
            className={cx('demo__get-started-button')}
          >
            {!user ? 'Sign In / Register to Get Started' : ((expanded && 'Start Chatting') || 'Return to Chat ')}
          </span>
        </div>
      </div>
    </div>
  );
};
export default ChatbotDemoVideo;
