import { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Tooltip from '../../Tooltip';

import operators from '../../../data/searchbar/operators';

import { addSearchItem, addNewWatchlistAssets } from '../../../actions/searchActions';

import Styles from './styles.module.scss';

export const AdvancedSearchOperators = memo(({ className = '', searchbarType }) => {
  const dispatch = useDispatch();

  const firstSearchResult = useSelector(({ searchReducers }) => (
    searchReducers.firstSearchResult
  ));
  const userKeywords = useSelector(({ searchReducers }) => (
    searchReducers.userKeywords
  ));

  const onOperatorClick = (value) => {
    const action = (searchbarType === 'createWatchlistsSearchbar' || searchbarType === 'manageWatchlistsSearchbar') ? (
      addNewWatchlistAssets
    ) : (
      addSearchItem
    );
    if (userKeywords) {
      const isRecommended = firstSearchResult?.recommended;
      if (isRecommended) {
        dispatch(
          action(firstSearchResult),
        );
      } else {
        dispatch(
          action({
            id: Math.floor(Math.random() * 1_000_000_000),
            name: userKeywords,
            itemType: 'ENTITY',
          }),
        );
      }
    }

    dispatch(
      action({
        id: Math.floor(Math.random() * 1_000_000_000),
        name: value,
        itemType: 'OPERATOR',
      }),
    );
  };

  return (
    <div className={`${Styles['advanced-search-operators']} ${className}`}>
      <div className={Styles['advanced-search-operators-hint']}>
        Type or click below to input advanced search operators.
      </div>
      <div className={Styles['advanced-search-operators-wrapper']}>
        <div className={Styles['advanced-search-operators-list']}>
          {operators.map((operator) => (
            <div
              key={operator.value}
              className={Styles['advanced-search-operator']}
              onClick={() => onOperatorClick(operator.value)}
            >
              {operator.value}
            </div>
          ))}
        </div>
        <Tooltip
          className={Styles['advanced-search-operators-tooltip']}
          tooltipClassName={Styles['advanced-search-operators-tooltip-content']}
        >
          <div className={Styles.hint}>
            Please use these operators to create complex queries.
            <br />
            <a
              href="https://www.cityfalcon.ai/help/articles/360007254833#boolean"
              target="_blank"
              rel="noreferrer noopener"
            >
              Learn more
            </a>
            &nbsp;about building complex queries.
          </div>
          <div className={Styles.examples}>
            Examples:
            <br />
            Boeing OR Airbus
            <br />
            iPhone AND China
            <br />
            Bitcoin AND NOT Ethereum
            <br />
            (EURUSD AND GBPUSD) OR AUDUSD
          </div>
        </Tooltip>
      </div>
    </div>
  );
});

AdvancedSearchOperators.defaultProps = {
  onOperatorClick: () => null,
};

export default AdvancedSearchOperators;
