import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import autoBind from 'react-autobind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';

import UserLoginComponent from '../UserLoginComponent';
import SearchbarInner from '../../SearchbarComponents/SearchbarInner';
import CFLink from '../../Shared/CFLink';
import HeaderContactButton from '../HeaderContactButton';

import logo from '../../../assets/logo.svg';
import RBILogo from '../../../assets/images/clients/rbi/rbi-logo-full.png';
import { RBI } from '../../../data/clients';
import withComponentName from '../../../decorators/withComponentName';
import { SILVER, GOLD } from '../../../data/permissions';

import permissionsDecorator from '../../../decorators/permissionsDecorator';

import * as searchActions from '../../../actions/searchActions';
import * as watchlistActions from '../../../actions/watchlistActions';

import StorageSvc from '../../../services/StorageSvc';

import Styles from './styles.module.scss';
import withRouter from '../../../decorators/withRouter';

const cx = classNames.bind(Styles);

export class WatchlistHeaderComponent extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.input = null;
    this.state = {
      isOpenSearch: false,
      isSearchIcon: true,
      inputValue: '',
      isSearchBarOpened: false,
      width: 1920,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.searchBarActive !== prevState.isSearchBarOpened) {
      return {
        isSearchBarOpened: nextProps.searchBarActive,
      };
    }
    return null;
  }

  onResize() {
    this.setState({
      width: window.innerWidth,
    });
  }

  getLogo() {
    const { client = null } = this.props;
    if (client && client.name === RBI.name) return RBILogo;
    return logo;
  }

  redirectToSearch(value) {
    if (!value.permalink) {
      StorageSvc.setItem('searchbarInput', JSON.stringify(value));
      window.location.href = '/search';
      return;
    }
    window.location.href = value.permalink;
  }

  toggleSearchBar(e) {
    e?.stopPropagation();
    const { actions, piwikEnabled } = this.props;
    const { inputValue, width, isOpenSearch } = this.state;

    const value = inputValue.trim();

    this.setState((prevState) => ({
      isSearchBarOpened: !prevState.isSearchBarOpened,
      isSearchIcon: !prevState.isSearchIcon,
    }), () => {
      const { isSearchBarOpened } = this.state;
      actions.focusSearchbar(!isSearchBarOpened, true);
      actions.blurSearchbar(!isSearchBarOpened);
      actions.toogleManageWatchlistsHeaderSearchbar(!isSearchBarOpened);
    });
    if (value !== '' && width > 760) {
      this.redirectToSearch(value);
      this.setState({
        inputValue: '',
      });
      return;
    }
    this.setState({
      isOpenSearch: !isOpenSearch,
    });

    if (piwikEnabled) _paq.push(['trackEvent', 'Other', 'Search Icon']);
  }

  getLogoSizes() {
    const { width } = this.state;
    let logoWidth = 90;
    let logoHeight = 43;

    if (width <= 1024 && width > 767) {
      logoWidth = 69;
      logoHeight = 33;
    } else if (width <= 767) {
      logoWidth = 63;
      logoHeight = 30;
    }
    return { logoHeight, logoWidth };
  }

  renderLogo() {
    const { client, isRailsContext } = this.props;
    const { logoHeight: height, logoWidth: width } = this.getLogoSizes();
    if (client) {
      return <img src={this.getLogo()} width={width} height={height} alt="Logo" loading="lazy" />;
    }

    return (
      <CFLink external={isRailsContext} to="/">
        <img
          src={logo}
          width={width}
          height={height}
          alt="Logo"
          loading="lazy"
        />
      </CFLink>
    );
  }

  render() {
    const {
      client = null,
      href,
      user,
      hiddenHeaderSearchbar,
      leftBarExists,
      blurSearchbar,
      searchBarActive,
      searchbarDisabled,
      activeWatchlistId,
      piwikEnabled,
      auth,
      accessLevels,
      permissionsInitialized,
      isRailsContext,
      isGoldPlan,
      isSilverPlan,
      location: { pathname },
      openedManageWatchlistsHeaderSearchbar,
      hiddenHeader,
    } = this.props;
    const {
      isOpenSearch,
      width,
      isSearchBarOpened,
    } = this.state;

    const rbi = Boolean(client && client.name === RBI.name);

    const userBlock = cx('user_block');

    const mobileSearchBar = cx('mobile_search_container', {
      opened: isOpenSearch,
    });

    const showBtn = ((permissionsInitialized
      && accessLevels.includes(SILVER)
      && !accessLevels.includes(GOLD))
      || (!user || (permissionsInitialized && !accessLevels.includes(SILVER))));

    const path = pathname || '';
    const boxShadowWithoutBorder = [
      '/news/onboarding/watchlist',
      '/news/onboarding/preferences',
      '/news/onboarding/registration',
      '/sign_in',
    ].indexOf(path) !== -1;

    const header = cx('header', {
      left_bar_exists: Boolean(leftBarExists),
      'is-search': href === '/search',
      rbi,
      open_mobile_searchbar: width <= 1024 && isSearchBarOpened
      && blurSearchbar && openedManageWatchlistsHeaderSearchbar,
      open_mobile_searchbar_small_screen: width < 770,
      '--box-shadow': boxShadowWithoutBorder,
    });

    return (
      <header className={header} id="watchlist-header">
        <div className={Styles.header_content}>
          <div className={cx('logoContainer', { isPremium: isGoldPlan || isSilverPlan })}>
            <div className={Styles.logo}>
              {this.renderLogo()}
            </div>
            <div className={cx('premium-icon-badge')}>
              {isGoldPlan
                && (
                  <div className={cx('premium-gold-icon-badge-container')}>
                    <div className={cx('premium-gold-icon-badge')}>
                      GOLD
                    </div>
                    <div className={cx('gold_tooltip')}>
                      CityFALCON&apos;s most powerful tools and data
                    </div>
                  </div>
                )}

              {isSilverPlan
                && (
                  <div className={cx('premium-silver-icon-badge-container')}>
                    <div className={cx('premium-silver-icon-badge')}>
                      SILVER
                    </div>
                    <div className={cx('silver_tooltip')}>
                      Data and analytics beyond The Basics
                    </div>
                  </div>
                )}
            </div>
          </div>
          {(searchBarActive && blurSearchbar
          && openedManageWatchlistsHeaderSearchbar && width > 1024) && (
            <div className={cx('searchbar_container', { active_search_bar: searchBarActive })} style={{ right: '200px' }}>
              <SearchbarInner
                searchStyles={[
                  'search_bar_full_width',
                  'main-header-searchbar',
                ]}
                fullWidth
                oneItem
                searchbarType="headerSearchbar"
                hiddenHeaderSearchbar // for activating searchbar from right => left,
                piwikEnabled={piwikEnabled}
                headerWLShowBtn={showBtn}
                hiddenHeader={hiddenHeader}
              />
            </div>
          )}
          {(searchBarActive && blurSearchbar
          && openedManageWatchlistsHeaderSearchbar && width <= 1024 && width >= 320) && (
            <div className={cx('mobile_searchbar_container', { active_search_bar: searchBarActive })}>
              <div className={mobileSearchBar}>
                <SearchbarInner
                  searchStyles={[
                    'search_bar_full_width',
                    'main-header-searchbar',
                  ]}
                  fullWidth
                  oneItem
                  searchbarType="headerSearchbar"
                  piwikEnabled={piwikEnabled}
                  hiddenHeader={hiddenHeader}
                />
              </div>
            </div>
          )}
          <div className={userBlock}>
            {((!blurSearchbar || !openedManageWatchlistsHeaderSearchbar)
            || width <= 1024) && !searchbarDisabled && (
              <div className={Styles.icon_container} onClick={this.toggleSearchBar}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            )}
            {permissionsInitialized
              && accessLevels.includes(SILVER)
              && !accessLevels.includes(GOLD)
              && (
                <div className={cx('upgrade_button', 'gold')}>
                  {isRailsContext ? (
                    <a
                      href="/pricing"
                    >
                      Upgrade to Gold
                    </a>
                  ) : (
                    <Link
                      to="/pricing"
                    >
                      Upgrade to Gold
                    </Link>
                  )}
                </div>
              )}
            {(!user || (permissionsInitialized && !accessLevels.includes(SILVER)))
              && !boxShadowWithoutBorder && (
              <div className={cx('upgrade_button')}>
                {isRailsContext ? (
                  <a
                    href="/pricing"
                  >
                    Start 7-day Premium Trial
                    <div className={cx('tooltip')}>Get Tweets, 1-year archive, more analytics & insights. No credit card required.</div>
                  </a>
                ) : (
                  <Link
                    to="/pricing"
                  >
                    Start 7-day Premium Trial
                    <div className={cx('tooltip')}>Get Tweets, 1-year archive, more analytics & insights. No credit card required.</div>
                  </Link>
                )}
              </div>
            )}
            <HeaderContactButton />
            <UserLoginComponent
              hiddenHeaderSearchbar={hiddenHeaderSearchbar}
              activeWatchlistId={activeWatchlistId}
              piwikEnabled={piwikEnabled}
              width={width}
              auth={auth}
              isRailsContext={isRailsContext}
            />
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = ({ searchReducers, userPreferencesReducer, subscriptions }) => ({
  focusSearch: searchReducers.focus,
  openedManageWatchlistsHeaderSearchbar: searchReducers.openedManageWatchlistsHeaderSearchbar,
  blurSearchbar: searchReducers.blur,
  searchBarActive: searchReducers.searchBarActive,
  activeWatchlistId: userPreferencesReducer.preferences.active_watchlist_id,
  openedSearchbarType: searchReducers.openedSearchbarType,
  accessLevels: subscriptions.permissions.access_levels,
  permissionsInitialized: subscriptions.permissionsInitialized,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...searchActions, ...watchlistActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  permissionsDecorator(withComponentName(withRouter(WatchlistHeaderComponent))),
);
