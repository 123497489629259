import FooterLink from '../FooterLink';

import withComponentName from '../../../decorators/withComponentName';

const FooterColumn = ({
  title, links, trackUserClick, isRailsContext,
}) => (
  <section>
    <h2>{title}</h2>
    {links.map((link, index) => (
      <FooterLink
        key={index}
        {...link}
        trackUserClick={trackUserClick}
        isRailsContext={isRailsContext}
      />
    ))}
  </section>
);

export default withComponentName(FooterColumn);
