import { createRef, forwardRef, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown } from 'react-bootstrap';
import differenceBy from 'lodash-es/differenceBy';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';

import { faGrid2Plus } from '@fortawesome/pro-solid-svg-icons/faGrid2Plus';
import TransparentButton from '../../../Buttons/PreferencesButtons/TransparentButton';
import withComponentName from '../../../../decorators/withComponentName';

import * as watchlistActions from '../../../../actions/watchlistActions';
import * as preferencesActions from '../../../../actions/preferencesActions';
import { piwikWatchlistTopicsQueryFormatter } from '../../../../helpers/helpers';

import { parseQueryLogicalStructure, queryItemWatchlistParser } from '../../../../helpers/watchlistHelperFunctions';

import Styles from './styles.module.scss';
import Tooltip from '../../../Tooltip';

const cx = classNames.bind(Styles);

export class NotificationWatchlistsTopicsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedWatchlistName: '',
      selectedWatchlist: {},
      loadingWatchlists: false,
      expandWatchlistTopics: false,
      open: false,
      showAllTopics: false,
      selectedWatchlistTopicsLength: null,
    };
    this.watchlistTopicItemContainer = createRef();
    this.mounted = true;
    this.references = {};
    autoBind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    this.mounted = true;
    this.setState({ loadingWatchlists: true });
    if (this.mounted) {
      this.setState({
        loadingWatchlists: false,
      });
    }
    actions.loadUserNotificationTopics(false);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      subscriptionTopics,
      activeWatchlist,
      activeWatchlistTopics,
      actions,
      watchlists,
    } = this.props;
    const { selectedWatchlist, selectedWatchlistName } = this.state;

    Object.keys(this.references).forEach((key) => {
      if (!this.references[key].current) delete this.references[key];
    });

    const { current: watchlistTopicItemContainer } = this.watchlistTopicItemContainer;

    if (!watchlistTopicItemContainer) return;

    if (
      prevProps.subscriptionTopics !== subscriptionTopics
      || prevProps.activeWatchlistTopics !== activeWatchlistTopics
    ) {
      if (!selectedWatchlist.id) {
        this.setState({
          selectedWatchlist: activeWatchlist,
        });
        if (activeWatchlist?.search_queries
          && (prevProps?.activeWatchlistTopics !== activeWatchlist?.search_queries)) {
          actions?.updateWatchlistAssets(activeWatchlist?.search_queries);
        }
      }
    }
    if (prevProps.subscriptionTopics !== subscriptionTopics
      || prevState.selectedWatchlistName !== selectedWatchlistName) {
      if (!selectedWatchlist.id) {
        const watchlistItems = watchlists.filter((item) => item.id === activeWatchlist.id)
          .map((item) => {
            const updatedQueries = item?.search_queries.map((topic) => ({
              ...topic,
              query: topic.query.replace(/(^"|"$)/g, ''),
            }));
            const updatedSubscriptionsTopics = subscriptionTopics.map((topic) => ({
              ...topic,
              query: topic.query.replace(/(^"|"$)/g, ''),
            }));
            return differenceBy(updatedQueries, updatedSubscriptionsTopics, 'query');
          });
        this.setState({
          selectedWatchlistTopicsLength: watchlistItems[0]?.length,
        });
      } else {
        const watchlistItems = watchlists.filter((item) => item.id === selectedWatchlist.id)
          .map((item) => {
            const updatedQueries = item?.search_queries.map((topic) => ({
              ...topic,
              query: topic.query.replace(/(^"|"$)/g, ''),
            }));
            const updatedSubscriptionsTopics = subscriptionTopics.map((topic) => ({
              ...topic,
              query: topic.query.replace(/(^"|"$)/g, ''),
            }));
            return differenceBy(updatedQueries, updatedSubscriptionsTopics, 'query');
          });
        this.setState({
          selectedWatchlistTopicsLength: watchlistItems[0]?.length,
        });
      }
    }
  }

  componentWillUnmount() {
    const {
      activeWatchlist,
      actions,
    } = this.props;
    this.mounted = false;
    if (activeWatchlist?.search_queries) actions.updateWatchlistAssets(activeWatchlist.search_queries);
  }

  handleShowMoreTopics() {
    this.setState(({ showAllTopics }) => ({ showAllTopics: !showAllTopics }));
  }

  getOrCreateRef(id) {
    if (!(id in this.references)) {
      this.references[id] = createRef();
    }

    return this.references[id];
  }

  getDropdownToggleValue() {
    const {
      watchlists,
      activeWatchlist,
    } = this.props;
    const {
      selectedWatchlistName,
      loadingWatchlists,
      selectedWatchlistTopicsLength,
      selectedWatchlist,
    } = this.state;

    let value;

    if (loadingWatchlists) {
      value = 'Loading...';
    } else if (!watchlists.length) {
      value = 'No Watchlist added to select';
    } else if (!selectedWatchlistName) {
      value = (
        <div className={Styles['notification-watchlist-item-container']}>
          <div className={Styles['notification-watchlist-item-name']}>
            {activeWatchlist.name}
          </div>
          <div className={Styles['notification-watchlist-item-counter']}>
            (
            {selectedWatchlistTopicsLength}
            {' '}
            /
            {' '}
            {selectedWatchlist?.search_queries?.length}
            )
          </div>
        </div>
      );
    } else {
      value = (
        <div className={Styles['notification-watchlist-item-container']}>
          <div className={Styles['notification-watchlist-item-name']}>
            {selectedWatchlistName}
          </div>
          <div className={Styles['notification-watchlist-item-counter']}>
            (
            {selectedWatchlistTopicsLength}
            {' '}
            /
            {' '}
            {selectedWatchlist?.search_queries?.length}
            )
          </div>
        </div>
      );
    }

    return value;
  }

  getDropdownItem() {
    const {
      watchlists, subscriptionTopics, activeWatchlist,
    } = this.props;
    const { selectedWatchlistName, open } = this.state;

    const dropdownItems = watchlists.map((item, index) => {
      const updatedQueries = item?.search_queries.map((topic) => ({
        ...topic,
        query: topic.query.replace(/(^"|"$)/g, ''),
      }));
      const updatedSubscriptionsTopics = subscriptionTopics.map((topic) => ({
        ...topic,
        query: topic.query.replace(/(^"|"$)/g, ''),
      }));
      const watchlistTopics = differenceBy(updatedQueries, updatedSubscriptionsTopics, 'query');
      return (
        <Dropdown.Item
          key={item.id}
          eventKey={index}
          active={(selectedWatchlistName || activeWatchlist.name) === item.name}
          onClick={() => this.selectWatchlist(item)}
        >
          <div className={Styles['notification-watchlist-item-container']}>
            <div className={Styles['notification-watchlist-item-name']}>
              {item.name}
            </div>
            <div className={Styles['notification-watchlist-item-counter']}>
              (
              {watchlistTopics?.length}
              {' '}
              /
              {' '}
              {item?.search_queries?.length}
              )
            </div>
          </div>
          {open && index === 0 && <FontAwesomeIcon icon={faChevronUp} />}
        </Dropdown.Item>
      );
    });
    return dropdownItems;
  }

  selectTopic(topic) {
    const { actions, subscriptionTopics, piwikEnabled } = this.props;

    const query = `"${topic.query}"`;
    actions.addTopicNotification(null, query, null, subscriptionTopics);

    if (piwikEnabled) {
      _paq.push(['trackEvent', 'Notification', 'Selected Topics and Securities', piwikWatchlistTopicsQueryFormatter(topic.entity_type, topic)]);
    }
  }

  selectWatchlist(selected) {
    const { actions, piwikEnabled } = this.props;

    this.setState({
      selectedWatchlistName: selected.name,
      selectedWatchlist: selected,
      expandWatchlistTopics: false,
    }, () => {
      const { selectedWatchlist, selectedWatchlistName } = this.state;

      actions.updateWatchlistAssets(selectedWatchlist.search_queries);

      if (piwikEnabled) _paq.push(['trackEvent', 'Notification', 'Topics from WL', selectedWatchlistName]);
    });
  }

  addAllTopics() {
    const {
      actions,
      subscriptionTopics,
      activeWatchlistTopics,
      piwikEnabled,
    } = this.props;

    const updatedActiveWatchlistTopics = activeWatchlistTopics.map((topic) => ({
      ...topic,
      query: topic.query.replace(/(^"|"$)/g, ''),
    }));
    const updatedSubscriptionsTopics = subscriptionTopics.map((topic) => ({
      ...topic,
      query: topic.query.replace(/(^"|"$)/g, ''),
    }));
    const watchlistTopics = differenceBy(updatedActiveWatchlistTopics, updatedSubscriptionsTopics, 'query');
    const topics = watchlistTopics.map((item) => `"${item.query}"`).toString().split(',').join(' ;; ');
    actions.addTopicNotification(
      null,
      topics,
      null,
      updatedSubscriptionsTopics,
      watchlistTopics,
    );

    if (piwikEnabled) _paq.push(['trackEvent', 'Notification', 'Add ALL from the list']);
  }

  showMoreWatchlistTopics() {
    const { expandWatchlistTopics } = this.state;
    this.setState({
      expandWatchlistTopics: !expandWatchlistTopics,
    });
  }

  toggleDropdown() {
    this.setState(({ open }) => ({
      open: !open,
    }));
  }

  getWatchlistTopics() {
    const { showAllTopics } = this.state;
    const {
      activeWatchlist,
      activeWatchlistTopics,
      subscriptionTopics,
      width,
    } = this.props;
    const updatedActiveWatchlistTopics = activeWatchlistTopics.map((topic) => ({
      ...topic,
      query: topic.query.replace(/(^"|"$)/g, ''),
    }));
    const updatedSubscriptionsTopics = subscriptionTopics.map((topic) => ({
      ...topic,
      query: topic.query.replace(/(^"|"$)/g, ''),
    }));
    const watchlistTopics = differenceBy(updatedActiveWatchlistTopics, updatedSubscriptionsTopics, 'query');
    if (showAllTopics) {
      return watchlistTopics.map((topic) => (
        <NotificationWatchlistTopicItem
          key={topic.id}
          watchlistId={activeWatchlist.id}
          topic={topic}
          selectTopic={this.selectTopic}
          ref={this.getOrCreateRef(topic.id)}
          width={width}
        />
      ));
    }
    return watchlistTopics.slice(0, 8).map((topic) => (
      <NotificationWatchlistTopicItem
        key={topic.id}
        watchlistId={activeWatchlist.id}
        topic={topic}
        selectTopic={this.selectTopic}
        ref={this.getOrCreateRef(topic.id)}
        width={width}
      />
    ));
  }

  render() {
    const {
      activeWatchlistTopics,
      subscriptionTopics,
      isActive,
    } = this.props;
    const {
      selectedWatchlistName,
      expandWatchlistTopics,
      open,
      showAllTopics,
    } = this.state;

    const updatedActiveWatchlistTopics = activeWatchlistTopics.map((topic) => ({
      ...topic,
      query: topic.query.replace(/(^"|"$)/g, ''),
    }));
    const updatedSubscriptionsTopics = subscriptionTopics.map((topic) => ({
      ...topic,
      query: topic.query.replace(/(^"|"$)/g, ''),
    }));
    const watchlistTopics = differenceBy(updatedActiveWatchlistTopics, updatedSubscriptionsTopics, 'query');
    const topicsWrapperClass = cx({
      'd-flex': true,
      'flex-wrap': true,
      'expand-topics': expandWatchlistTopics,
    });
    return (
      <div className={cx('notification-watchlist-topics', { active: isActive })}>
        <div className={Styles['notification-watchlist-dropdown-container']}>
          <Dropdown
            onToggle={this.toggleDropdown}
            id="preferences-watchlist-dropdown"
            className={Styles['notification-watchlist-dropdown']}
            show={open}
          >
            <Dropdown.Toggle id="watchlist-dropdown">
              {this.getDropdownToggleValue()}
              <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
            </Dropdown.Toggle>
            <Dropdown.Menu
              rootCloseEvent="click"
              show={open}
              flip={false}
            >
              {this.getDropdownItem(selectedWatchlistName)}
            </Dropdown.Menu>
          </Dropdown>
          {watchlistTopics.length !== 0 && (
          <Tooltip
            icon={(
              <TransparentButton
                className={Styles['add-all-wl-topics']}
                onClick={this.addAllTopics}
                disabled={watchlistTopics.length === 0
                  || watchlistTopics.length > (50 - subscriptionTopics.length)}
                noHover
              >
                <FontAwesomeIcon icon={faGrid2Plus} />
                Add ALL Topics
              </TransparentButton>
            )}
            className={Styles['add-all-topics']}
          >
            You may receive notifications for up to 50 topics.
            Remove some notifications or shorten your watchlist first.
          </Tooltip>
          )}

        </div>
        <div ref={this.watchlistTopicItemContainer} className={cx('notifications-watchlist-topic-items', { active: isActive })}>
          <ul className={topicsWrapperClass}>
            {this.getWatchlistTopics()}
          </ul>
          {watchlistTopics.length > 8 && (
          <div className={cx('showTopicsBtn')} onClick={this.handleShowMoreTopics}>
            Show
            {' '}
            {showAllTopics ? 'Less' : 'All'}
            {' '}
            <span>
              <FontAwesomeIcon icon={showAllTopics ? faChevronUp : faChevronDown} />
            </span>
          </div>
          )}
          {watchlistTopics.length === 0 && subscriptionTopics.length !== 50
            && (
              <div className={Styles['notifications-watchlist-topic-msg']}>
                All Topics from this Watchlist have already been added.
              </div>
            )}
          {subscriptionTopics.length === 50
            && (
            <div className={cx('removeTopicsMsg')}>
              You already have 50 topics. Please remove some before adding others.
            </div>
            )}
        </div>
      </div>
    );
  }
}

const NotificationWatchlistTopicItem = forwardRef((props, ref) => {
  const { selectTopic, topic, width } = props;
  let name;
  if (topic.logical_structure instanceof Object) {
    name = parseQueryLogicalStructure(topic.logical_structure, true, width);
  } else {
    ({ name } = topic.query ? queryItemWatchlistParser(topic.query) : topic);
  }

  return (
    <li
      className={Styles['watchlist-topic-item']}
      ref={ref}
      onClick={() => selectTopic(topic)}
    >
      <div>{name}</div>
      <FontAwesomeIcon icon={faPlus} />
    </li>
  );
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...watchlistActions, ...preferencesActions }, dispatch),
});

const mapStateToProps = (state) => ({
  activeWatchlist: state.watchlistReducer.activeWatchlist,
  activeWatchlistTopics: state.watchlistReducer.activeWatchlistTopics,
  watchlists: state.watchlistReducer.watchlistsList,
  token: state.watchlistReducer.userToken,
  activeWatchlistId: state.watchlistReducer.activeWatchlistId,
  subscriptionTopics: state.userPreferencesReducer.subscriptionTopics,
  width: state.common.width,
});

export default withComponentName(
  connect(mapStateToProps, mapDispatchToProps)(NotificationWatchlistsTopicsComponent),
);
