import {
  SUBCONTINENT,
  COUNTRY,
  STATE,
  CITY,
  AREA,
  PLACE,
} from '../topicTypes';

export default {
  subcontinents: SUBCONTINENT,
  countries: COUNTRY,
  states: STATE,
  cities: CITY,
  areas: AREA,
  places: PLACE,
};
