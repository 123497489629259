import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.scss';

const BluePreloader = ({ active, className = '' }) => (
  <div className={`${Styles.search_preloader} ${className}`}>
    {active && (
      <div className={Styles.preloader_wrapper}>
        <div className={`${Styles.line} ${Styles.odd}`} />
        <div className={`${Styles.line} ${Styles.even}`} />
        <div className={`${Styles.line} ${Styles.odd_1}`} />
        <div className={`${Styles.line} ${Styles.even_1}`} />
      </div>
    )}
  </div>
);

export default withComponentName(BluePreloader);
