import { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getPageMetaData } from '../../services/dbServices/userServices';

const withMetatag = (WrappedComponent, initialUrl = '') => {
  const url = initialUrl.length > 1 && initialUrl[0] === '/' ? initialUrl.slice(1) : initialUrl;

  class PageHeadData extends Component {
    constructor(props) {
      super(props);
      autoBind(this);
      this.state = {
        title: '',
        dynamicSelectors: '',
        metatags: [],
      };
    }

    async componentDidMount() {
      const data = await getPageMetaData(url);
      Object.values(data).forEach(({
        title, dynamic_meta_tags_query_selectors: dynamicSelectors, meta_tags: metatags,
      }) => {
        this.setState({
          title,
          dynamicSelectors,
          metatags,
        }, this.setMetaData);
      });
    }

    setMetaData() {
      const { dynamicSelectors } = this.state;

      this.removeCurrentMetaTags();

      this.setPageTitle();

      this.createOrUpdateMetaTag('dynamic-meta-tags-current-path', url);
      if (dynamicSelectors) {
        this.createOrUpdateMetaTag('dynamic-meta-tags-query-selectors', dynamicSelectors);
      }

      this.createNewMetaTags();
    }

    setPageTitle() {
      const { title } = this.state;

      if (!title) return;

      document.title = title;
    }

    createNewMetaTags() {
      const { metatags } = this.state;

      if (!metatags || metatags.length < 1) return;

      metatags.forEach((metaTagData) => {
        this.createMetaTag(metaTagData);
      });
    }

    createOrUpdateMetaTag(name, content) {
      const query = `meta[name="${name}"]`;
      const metaTag = document.querySelector(query);

      if (metaTag) {
        metaTag.content = content;
      } else {
        this.createMetaTag({
          name,
          content,
        });
      }
    }

    createMetaTag(attributes) {
      const newMetaTag = document.createElement('meta');

      Object.entries(attributes).forEach(([key, attribute]) => {
        newMetaTag.setAttribute(key, attribute);
      });

      document.head.appendChild(newMetaTag);
    }

    removeCurrentMetaTags() {
      const querySelectorsString = this.metaTagContent('meta[name="dynamic-meta-tags-query-selectors"]');

      if (!querySelectorsString) return;

      const querySelectors = querySelectorsString.split('&&&');

      querySelectors.forEach((query) => {
        const element = document.querySelector(query);

        if (element) {
          if (element.remove) {
            element.remove();
          } else {
            // checked cause IE do not support method remove
            element.parentNode.removeChild(element);
          }
        }
      });
    }

    currentPath() {
      return this.metaTagContent('meta[name="dynamic-meta-tags-current-path"]');
    }

    metaTagContent(query) {
      const metaTag = document.querySelector(query);

      return metaTag ? metaTag.content : undefined;
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  PageHeadData.displayName = WrappedComponent.name || WrappedComponent.displayName;

  return PageHeadData;
};

const mapStateToProps = (state) => ({
  searchBarActive: state.searchReducers.searchBarActive,
});

const connectedReduxWithMetatag = compose(
  connect(mapStateToProps, null),
  withMetatag,
);

export default connectedReduxWithMetatag;
