import { Modal } from 'react-bootstrap';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import withComponentName from '../../../../decorators/withComponentName';
import BluePreloader from '../../../GeneralComponents/BluePreloader';

import {
  checkDuplicates, checkOnValidBracketsName, getDefaultWatchlistName, isSpecialCharacters,
} from '../../../../helpers/watchlistHelperFunctions';

import { getGuestUserWatchlists } from '../../../../helpers/guestUsersWatchlists';

import Styles from './styles.module.scss';

const NameWatchlistModal = ({
  openModal, handleCloseModal, handleConfirmNameWl,
  watchlistsList,
  handleBackButton,
}) => {
  const cx = classNames.bind(Styles);

  const defaultName = getDefaultWatchlistName(watchlistsList);

  const [inputValue, setInputValue] = useState(defaultName);
  const [errors, setErrors] = useState({ status: false, text: '' });

  const token = useSelector(({ watchlistReducer }) => (watchlistReducer.userToken));
  const maxWatchlists = useSelector(({ subscriptions }) => (
    subscriptions.permissions.max_watchlists
  ));
  const watchlistCreationBegin = useSelector(({ watchlistReducer }) => (
    watchlistReducer.watchlistCreationBegin
  ));

  const validateWatchlistName = (newWatchlistName) => {
    const isDuplicates = checkDuplicates(watchlistsList, newWatchlistName);
    const isSpecCharacters = isSpecialCharacters(newWatchlistName);

    if (newWatchlistName?.length > 50) {
      setErrors({ status: true, text: 'The name is too long. Please use not more than 50 characters.' });
    } else if (!newWatchlistName?.trim().length && !inputValue?.trim()) {
      setErrors({ status: true, text: 'A watchlist name is required' });
    } else if (isDuplicates) {
      setErrors({ status: true, text: 'This name is already in use. Please choose another one. ' });
    } else if (isSpecCharacters || checkOnValidBracketsName(newWatchlistName)) {
      setErrors({ status: true, text: 'Special characters are not allowed ' });
    } else {
      setErrors({ status: false, text: '' });
    }
  };

  const validateGuestMaxWatchlistAmount = () => {
    const guestWatchlists = getGuestUserWatchlists();
    if (guestWatchlists.length >= maxWatchlists) {
      setErrors({
        status: true,
        text: (
          <>
            Your current plan allows
            &nbsp;
            { maxWatchlists }
            &nbsp;
            <a href="/pricing">Upgrade</a>
            &nbsp;
            for more.
          </>
        ),
      });
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateWatchlistName(inputValue);

    if (!token) {
      const isValidMaxWatchlistsAmount = validateGuestMaxWatchlistAmount();
      if (isValidMaxWatchlistsAmount && errors.status === false) {
        handleConfirmNameWl(inputValue);
      }
      return;
    }

    if (errors.status === false) {
      handleConfirmNameWl(inputValue);
    }
  };

  const handleChange = (e) => {
    const { value: newWatchlistName } = e.target;
    setInputValue(newWatchlistName);
  };

  const setDefaultName = () => {
    const defaultName = getDefaultWatchlistName(watchlistsList);
    setInputValue(defaultName);
  };

  useEffect(() => {
    setDefaultName();
  }, [watchlistsList]);

  useEffect(() => {
    validateWatchlistName(inputValue);
  }, [inputValue]);

  return (
    <Modal
      show={openModal}
      dialogClassName={cx('modal')}
      onHide={() => {
        setInputValue(defaultName);
        handleCloseModal();
      }}
      className={`manage-wl-change-name-modal ${cx('modal__wrapper', 'manage-wl-change-name-modal', 'dialog-modal-wrapper')}`}
      centered
    >
      <p className={cx('modal__title')}>Name your watchlist</p>
      <form
        onSubmit={handleSubmit}
        className={cx('modal__form', { errors: errors.status })}
      >
        <input
          type="text"
          value={inputValue}
          maxLength="51"
          onChange={handleChange}
          className={cx('modal__input', { errors: errors.status })}
        />
        {errors.status && <span className={cx('modal__error-message')}>{errors.text}</span>}
        <div className={cx('modal__buttons', { errors: errors.status, hide: watchlistCreationBegin })}>
          <button
            onClick={() => {
              handleBackButton();
              setDefaultName();
            }}
            type="button"
            className={cx('modal__button', 'back')}
          >
            Back
          </button>
          <button
            type="submit"
            className={cx('modal__button', 'confirm', { disabled: errors.status })}
          >
            Confirm
          </button>

        </div>
        <div className={cx('modal__wait_loader', { hide: !watchlistCreationBegin })}>
          <BluePreloader active={watchlistCreationBegin} />
        </div>
      </form>
    </Modal>
  );
};

export default withComponentName(NameWatchlistModal);
