export default [
  {
    symbol: '(',
    value: '(',
  },
  {
    symbol: ')',
    value: ')',
  },
  {
    symbol: 'AND',
    value: 'AND',
  },
  {
    symbol: 'OR',
    value: 'OR',
  },
  {
    symbol: 'AND NOT',
    value: 'AND NOT',
  },
];
