import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import {
  isBrowser, isIOS, isMobile, isTablet,
} from 'react-device-detect';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import withComponentName from '../../decorators/withComponentName';
import { showPromo, hidePromo } from '../../actions/promoBarActions';

import StorageSvc from '../../services/StorageSvc';

import Styles from './styles.module.scss';

class MobileNotification extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.promo = null;
    this.state = {
      isOpen: null,
    };
  }

  componentDidMount() {
    this.setState({
      isOpen: StorageSvc.getItem('mobilePopupSeen') !== 'true',
    });
  }

  setNotificationData() {
    return (isIOS)
      ? {
        generalText: 'CityFALCON App for iOS',
        secondaryText: 'Download for free from App Store',
        link: 'https://itunes.apple.com/us/app/cityfalcon/id1148560018?ls=1&mt=8',
      }
      : {
        generalText: 'CityFALCON App for Android',
        secondaryText: 'Download for free from Google Play',
        link: 'https://play.google.com/store/apps/details?id=com.cityfalcon',
      };
  }

  setElement(elem) {
    if (elem) {
      this.promo = elem;
      setTimeout(this.shiftContent, 100);
    }
  }

  closeBar() {
    const { hidePromo, triggerMainPromo } = this.props;

    this.setState({
      isOpen: false,
    });

    hidePromo(0, 'desktopPromo');
    StorageSvc.setItem('mobilePopupSeen', 'true');
    triggerMainPromo();
  }

  shiftContent() {
    const { showPromo } = this.props;

    showPromo(this.promo.offsetHeight, 'mobilePromobar');
  }

  render() {
    const { isOpen } = this.state;
    const { generalText = '', secondaryText = '', link = '' } = this.setNotificationData();

    return (
      <div>
        {isOpen && (!isBrowser || isTablet || isMobile) && (
          <div className={Styles.mobile_notification_container} ref={this.setElement}>
            <div className={Styles.mobile_notification_text}>
              <span>{generalText}</span>
              <span>{secondaryText}</span>
            </div>
            <a className={Styles.install_app_button} href={link}>
              Try It Out!
            </a>
            <FontAwesomeIcon
              className={Styles.close_promo}
              icon={faXmark}
              onClick={this.closeBar}
            />
          </div>
        )}
      </div>
    );
  }
}

MobileNotification.propTypes = {
  triggerMainPromo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  statePromo: state.promobar,
});

const mapDispatchToProps = (dispatch) => ({
  showPromo: (height, type) => dispatch(showPromo(height, type)),
  hidePromo: () => dispatch(hidePromo()),
});

export default withComponentName(connect(mapStateToProps, mapDispatchToProps)(MobileNotification));
