import { PureComponent } from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import { isMobile, withOrientationChange } from 'react-device-detect';

import HeaderLeftSide from './HeaderLeftSide';
import SearchbarInner from '../../SearchbarComponents/SearchbarInner';
import CTAbuttons from './CTAbuttons';
import HeaderRightSide from './HeaderRightSide';
import CFLink from '../../Shared/CFLink';

import * as searchActions from '../../../actions/searchActions';
import * as watchlistActions from '../../../actions/watchlistActions';
import * as headerActions from '../../../actions/header.actions';

import Logo from '../../../assets/logo.svg';

import withComponentName from '../../../decorators/withComponentName';

import { getBrowserScrollTop } from '../../../helpers/commonHelpers';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export class MainHeaderContent extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      scrolled: null,
      iconActive: false,
      isMobileFixed: false,
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', this.scrollHandler);
    this.scrollHandler();
  }

  componentDidUpdate(prevProps) {
    const { blurSearchbar, searchBarActive } = this.props;

    if (
      (!blurSearchbar && prevProps.blurSearchbar !== blurSearchbar)
      || (prevProps.searchBarActive !== searchBarActive && !searchBarActive)
    ) {
      this.setState({
        iconActive: false,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHandler);
  }

  scrollHandler() {
    const {
      user,
      promoHeight,
      width,
      location: {
        pathname: path,
      },
    } = this.props;
    const { scrolled } = this.state;

    const scrollpoint = getBrowserScrollTop();

    this.setState({
      isMobileFixed: scrollpoint > promoHeight,
    });

    // this feature only allowed for home page not registered user
    if (width < 768 || (path !== '/' && path !== '/segments/brokers') || !!user) {
      this.setState({
        scrolled: false,
      });
      return;
    }

    if (!scrolled && window.pageYOffset > 450) {
      this.setState({
        scrolled: true,
      });
    }
    if (scrolled && window.pageYOffset <= 450) {
      this.setState({
        scrolled: false,
      });
    }
  }

  isDirectoryPath() {
    const { location } = this.props;
    return location.pathname.startsWith('/news/directory');
  }

  toggleMobileSearchBar(e) {
    e?.stopPropagation();
    const { actions } = this.props;

    this.setState(({ iconActive }) => ({
      iconActive: !iconActive,
    }), () => {
      const { iconActive } = this.state;
      actions.focusSearchbar(iconActive, true);
      actions.blurSearchbar(iconActive);
      if (iconActive) {
        actions.setHeaderHeight(140);
      } else {
        actions.setHeaderHeight(79);
      }
    });
  }

  sendPiwikGetStarted() {
    const { piwikEnabled } = this.props;

    if (piwikEnabled) _paq.push(['trackEvent', 'Home page', 'Get started (header)']);
  }

  render() {
    const {
      leftBarExists = false,
      blurSearchbar,
      promoHeight,
      piwikEnabled,
      lightLeftBar,
      navMenuRef,
      userMenuRef,
      openedSearchbarType: openedSearchbarTypesList,
      searchBarActive,
      auth,
      isRailsContext,
      width,
      location: {
        pathname: path,
      },
      showHPHeaderSearchbar,
      isGoldPlan,
      isSilverPlan,
      showHPHeaderBoxShadow,
      hiddenHeader,
    } = this.props;
    const {
      iconActive,
      scrolled,
      isMobileFixed,
    } = this.state;

    const openedSearchbarType = openedSearchbarTypesList.find((type) => type.opened)?.type;

    // detection which pages show CTA buttons
    const showCTAButtons = [
      '/products/api/financial-news',
      '/financial-enterprise-solutions',
      '/products/api/financial-news/pricing-api/commercial',
      '/products/api/financial-news/pricing-api/noncommercial',
      '/products/api/financial-news/pricing-historical',
    ].includes(path);

    const hideSearchBar = [
      '/financial-enterprise-solutions',
    ].includes(path) || (path === '/' && !showHPHeaderSearchbar);

    const headerContent = cx('header_content', {
      'financial-news-header': ['/products/api/financial-news'].includes(path),
    });

    const homepageHeader = path === '/';

    const checkTop = (promoHeight && width > 767);
    const checkForMobile = width < 768 && (isMobileFixed || !promoHeight);
    const mobileSearchbarOpen = width <= 1250 && iconActive && blurSearchbar;
    const headerClass = cx('header', {
      scrolled: scrolled && path !== '/segments/brokers',
      open_mobile_searchbar: mobileSearchbarOpen,
      left_bar_exists: leftBarExists,
      'homepage-header': lightLeftBar,
      has_promo: !checkTop && !checkForMobile,
      no_box: homepageHeader && showHPHeaderBoxShadow && width <= 1023 && !mobileSearchbarOpen,
    });

    const mobileSearchBar = cx('mobile_searchbar_container', {
      is_opened: (iconActive && blurSearchbar) || iconActive,
      active_search_bar: searchBarActive,
    });

    // show CTA buttons from left side on some pages and on tablet/mobile
    const showLeftCTA = showCTAButtons && width <= 1023 && ['/products/api/financial-news'].includes(path);

    if (this.isDirectoryPath()) return null;

    return (
      <>
        {!!checkForMobile && (
          <div className={Styles.temp_header} />
        )}
        <header
          className={headerClass}
          id="main-header"
          style={{
            top: checkTop ? `${promoHeight}px` : '0',
            zIndex: isMobile ? 100001 : 1001,
          }}
        >
          <div className={headerContent}>
            {(!searchBarActive || iconActive || openedSearchbarType !== 'headerSearchbar') ? (
              <HeaderLeftSide navMenuRef={navMenuRef} isRailsContext={isRailsContext} />
            ) : (
              <div className={Styles['header-left-stub']} />
            )}
            {showLeftCTA && (
              <CTAbuttons
                path={path}
                isRailsContext={isRailsContext}
              />
            )}
            <div className={cx('logoContainer', { isPremium: isGoldPlan || isSilverPlan })}>
              <div className={Styles['main-logo']}>
                <CFLink
                  external={isRailsContext}
                  to="/"
                >
                  <img
                    src={Logo}
                    width={90}
                    height={43}
                    alt="Logo"
                  />
                </CFLink>
              </div>
              <div className={cx('premium-icon-badge')}>
                {isGoldPlan
                  && (
                    <div className={cx('premium-gold-icon-badge-container')}>
                      <div className={cx('premium-gold-icon-badge')}>
                        GOLD
                      </div>
                      <div className={cx('gold_tooltip')}>
                        CityFALCON&apos;s most powerful tools and data
                      </div>
                    </div>
                  )}

                {isSilverPlan
                  && (
                    <div className={cx('premium-silver-icon-badge-container')}>
                      <div className={cx('premium-silver-icon-badge')}>
                        SILVER
                      </div>
                      <div className={cx('silver_tooltip')}>
                        Data and analytics beyond The Basics
                      </div>
                    </div>
                  )}
              </div>
            </div>
            {(
              searchBarActive
              && (
                (width <= 1250 && width >= 320 && !hideSearchBar)
                || openedSearchbarType === 'headerSearchbar'
              )
            ) && (
              <div className={mobileSearchBar}>
                <SearchbarInner
                  searchStyles={[
                    'search_bar_full_width',
                    'main-header-searchbar',
                    homepageHeader && 'homepage_header',
                  ]}
                  fullWidth
                  oneItem
                  searchbarType="headerSearchbar"
                  activeMobileHeaderSearch={iconActive}
                  piwikEnabled={piwikEnabled}
                  isRailsContext={isRailsContext}
                  homepageHeader={homepageHeader}
                  hiddenHeader={hiddenHeader}
                />
              </div>
            )}
            <HeaderRightSide
              sendPiwikGetStarted={this.sendPiwikGetStarted}
              hideSearchBar={hideSearchBar && openedSearchbarType !== 'headerSearchbar'}
              showCTAButtons={showCTAButtons}
              width={width}
              path={path}
              auth={auth}
              showLeftCTA={showLeftCTA}
              toggleMobileSearchBar={this.toggleMobileSearchBar}
              isRailsContext={isRailsContext}
              homepageHeader={homepageHeader}
              piwikEnabled={piwikEnabled}
              userMenuRef={userMenuRef}
            />
          </div>
        </header>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  promoHeight: state.promobar.height,
  focusSearch: state.searchReducers.focus,
  blurSearchbar: state.searchReducers.blur,
  searchBarActive: state.searchReducers.searchBarActive,
  openedSearchbarType: state.searchReducers.openedSearchbarType,
  width: state.common.width,
  showHPHeaderSearchbar: state.header.showHPHeaderSearchbar,
  showHPHeaderBoxShadow: state.header.showHPHeaderBoxShadow,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...searchActions,
    ...watchlistActions,
    ...headerActions,
  }, dispatch),
});

export default withComponentName(
  connect(mapStateToProps, mapDispatchToProps)(
    withOrientationChange(withRouter(MainHeaderContent)),
  ),
);
