import NewTag from '../../Shared/NewTag';

const CustomHeader = () => (
  <div className="react-chatbot-kit-chat-header-custom">
    <div className="header__tags">
      <NewTag />
    </div>
    <span className="header__title">
      CityFALCON Chat
    </span>
  </div>
);

export default CustomHeader;
