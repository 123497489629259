import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import FooterContacts from '../../components/FooterComponents/FooterContacts';
import FooterColumn from '../../components/FooterComponents/FooterColumn';

import { footerData, footerConfig } from '../../data/footerData';
import { isRailContextPages } from '../../data/webPageData';

import { stringToBool } from '../../helpers/commonHelpers';

import StorageSvc from '../../services/StorageSvc';

import useFinprompt from '../../providers/FinpromptProvider/useFinprompt';

import Styles from './footer.module.scss';

const cx = classNames.bind(Styles);

const Footer = ({ piwikEnabled, railsContext }) => {
  const { sections } = footerConfig;
  const location = useLocation();
  const [footerStyles, setFooterStyles] = useState();

  const cookiesPolicyStatus = useSelector((state) => state.common.cookiesPolicyStatus);
  const { isFinpromptPages } = useFinprompt();

  useEffect(() => {
    const cookiesPolicyStatusCached = stringToBool(StorageSvc.getItem('cookies_policy_status') || 'false');
    setFooterStyles({
      bottom: cookiesPolicyStatusCached || !isMobile ? '0' : '50px',
      marginTop: cookiesPolicyStatusCached || !isMobile ? 'auto' : '50px',
    });
  }, [cookiesPolicyStatus]);

  const trackUserClick = (linkTitle) => {
    if (piwikEnabled) _paq.push(['trackEvent', 'Footer', linkTitle]);
  };

  const path = location?.pathname || railsContext?.pathname || '';
  const hideFooterComponent = [
    '/news/onboarding/topicclass',
    '/news/onboarding/watchlist',
    '/news/onboarding/preferences',
    '/news/onboarding/registration',
    '/sign_in',
    '/chatbot',
    '/finprompt',
  ].indexOf(path) !== -1;

  if (hideFooterComponent || isFinpromptPages) return null;

  const pagePath = path.split('/')[1] || '';
  const isRailsContext = !!Object.keys(railsContext || {}).length
    || !!isRailContextPages[pagePath];

  const mainFooterClass = cx('main-footer', { 'api-pricing-page': location.pathname.includes('/products/api/financial-news/pricing-api/') });

  return (
    <footer id="common-footer" style={footerStyles} className={mainFooterClass}>
      <div className={Styles['common-footer-inner']}>
        <div className={Styles['common-footer-sections']}>
          <FooterContacts
            contacts={footerData}
            piwikEnabled={piwikEnabled}
            trackUserClick={trackUserClick}
            isRailsContext={isRailsContext}
          />
          {sections.map((item) => (
            <FooterColumn
              key={item.id}
              {...item}
              piwikEnabled={piwikEnabled}
              trackUserClick={trackUserClick}
              isRailsContext={isRailsContext}
            />
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
