import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';

import withComponentName from '../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const MainPromobar = ({
  url,
  text,
  cta,
  sendPromobarAction,
  closePromo,
}) => (
  <div className={Styles.promobar}>
    <div className={Styles.promobar_inner}>
      <span className={Styles.promobar_text}>
        {text}
      </span>
      {cta && (
        <a
          onClick={sendPromobarAction}
          href={url}
          className={cx('promobar_cta', 'animated', 'wobble')}
        >
          {cta}
        </a>
      )}
    </div>
    <FontAwesomeIcon
      className={Styles.close_promo}
      icon={faXmark}
      onClick={closePromo}
    />
  </div>
);

MainPromobar.propTypes = {
  closePromo: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
};

export default withComponentName(MainPromobar);
