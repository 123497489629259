import { createRef, Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import loadable from '@utils/loadable';

import Promobar from '../../PromoBar';
import WatchlistHeaderComponent from '../WatchlistHeaderComponent';
import MainHeaderComponent from '../MainHeaderComponent';

import withRouter from '../../../decorators/withRouter';
import withComponentName from '../../../decorators/withComponentName';

import {
  hiddenHeaderSearchbarCheck,
  isLeftBarHiddenCheck,
} from '../../../data/webPageData';
import { GOLD, SILVER } from '../../../data/permissions';

import { setIsHiddenHeaderSearchbar } from '../../../actions/header.actions';

import withFinprompt from '../../../decorators/withFinprompt';

import Styles from './styles.module.scss';

const LeftBar = loadable(() => import('../../LeftBar'));
const SecondStepSocialModal = loadable(() => import('../../Modals/AuthModals/SecondStepSocialModal'));

const cx = classNames.bind(Styles);

class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getHeaderSearchBarState();
    this.navMenuRef = createRef();
    this.userMenuRef = createRef();
    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    const { location, railsContext } = this.props;
    if (
      (location && location.pathname !== prevProps?.location?.pathname)
      || (railsContext && railsContext.pathname !== prevProps?.railsContext?.pathname)
    ) {
      this.setState(this.getHeaderSearchBarState());
    }
  }

  getHeaderSearchBarState() {
    const { location, railsContext, dispatchSetIsHiddenHeaderSearchbar } = this.props;
    const pathname = location?.pathname || railsContext?.pathname || '';
    const path = pathname.split('/');
    const isHeaderSearchBar = hiddenHeaderSearchbarCheck[path[1]] ?? false;
    const onboarding = pathname.startsWith('/news/onboarding');

    let isLeftBarHidden;
    if (path[1] === 'news' && !onboarding) {
      isLeftBarHidden = isLeftBarHiddenCheck[path[2]] ?? false;
    } else {
      isLeftBarHidden = isLeftBarHiddenCheck[path[1]] ?? false;
    }

    if (dispatchSetIsHiddenHeaderSearchbar) dispatchSetIsHiddenHeaderSearchbar(isHeaderSearchBar);

    return {
      hiddenHeaderSearchbar: isHeaderSearchBar,
      lightLeftBar: !isHeaderSearchBar,
      leftBarExists: !isLeftBarHidden,
    };
  }

  closeNavMenu() {
    if (this.navMenuRef.current) {
      this.navMenuRef.current.closeMenu();
    }
    if (this.userMenuRef.current) {
      this.userMenuRef.current.closeUserBlock();
    }
  }

  render() {
    const {
      hiddenHeaderSearchbar,
      lightLeftBar,
      leftBarExists,
    } = this.state;
    const {
      user,
      inRouter,
      href,
      path,
      showProductsPagesLeftbar,
      width,
      isRailsContext,
      railsContext,
      location,
      isFinpromptPages,
    } = this.props;

    const { permissions } = this.props;
    const { access_levels: accessLevels } = permissions;
    const isGoldPlan = accessLevels?.includes(GOLD);
    const isSilverPlan = accessLevels?.includes(SILVER) && !accessLevels?.includes(GOLD);

    const searchbarDisabled = [
      '/search',
      '/news/directory/cryptocurrencies/full_details',
    ].includes(path);
    const headerWrapperClass = cx('header-wrapper');

    const onboarding = location.pathname.startsWith('/news/onboarding');
    const isChatbotPage = ['/chatbot'].includes(path);

    if (isFinpromptPages) return null;

    if (hiddenHeaderSearchbar) {
      return (
        <div className={headerWrapperClass}>
          <Promobar {...this.props} location={path} />
          <WatchlistHeaderComponent
            {...this.props}
            searchbarDisabled={searchbarDisabled}
            isRailsContext={isRailsContext}
            leftBarExists={leftBarExists}
            isGoldPlan={isGoldPlan}
            isSilverPlan={isSilverPlan}
            hiddenHeader={hiddenHeaderSearchbar}
          />
          {leftBarExists && (
            <LeftBar
              {...this.props}
              href={path || href}
              lightLeftBar={lightLeftBar}
              onboarding={onboarding}
            />
          )}
        </div>
      );
    }

    return (
      <>
        <div className={headerWrapperClass}>
          <Promobar {...this.props} location={path} />
          <MainHeaderComponent
            {...this.props}
            inRouter={inRouter}
            searchbarDisabled={searchbarDisabled}
            navMenuRef={this.navMenuRef}
            leftBarExists={leftBarExists}
            isGoldPlan={isGoldPlan}
            isSilverPlan={isSilverPlan}
            userMenuRef={this.userMenuRef}
            hiddenHeader={hiddenHeaderSearchbar}
          />
          {
            (showProductsPagesLeftbar
              || (leftBarExists && (!showProductsPagesLeftbar || ['/', '/learn-more'].includes(path)))
              || (width <= 1023)
            ) && (
              <LeftBar
                isDetailsPage
                user={user}
                href={path}
                lightLeftBar={(['/', '/learn-more'].includes(path) || lightLeftBar) && !isChatbotPage}
                isRailsContext={isRailsContext}
                closeNavMenu={this.closeNavMenu}
                isChatbotPage={isChatbotPage}
              />
            )
          }
        </div>
        {railsContext.href.includes('/?registered=true') && (
          <SecondStepSocialModal />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ common, subscriptions }) => ({
  width: common.width,
  permissions: subscriptions.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetIsHiddenHeaderSearchbar: (data) => dispatch(setIsHiddenHeaderSearchbar(data)),
});

export default withComponentName(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withFinprompt(HeaderComponent))),
);
