import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/pro-regular-svg-icons/faCommentDots';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const HeaderContactButton = ({ className }) => {
  const onClick = (e) => {
    const contactUsForm = document.querySelector('#contactus-form');

    if (contactUsForm) {
      e.preventDefault();
      contactUsForm.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <a href="/contact-us" role="button" className={cx('header-contact-us', className)} onClick={onClick}>
      <span className={Styles.text}>Contact Us</span>
      <FontAwesomeIcon className={Styles.icon} icon={faCommentDots} />
    </a>
  );
};

export default HeaderContactButton;
