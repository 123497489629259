import { Link } from 'react-router-dom';
import loadable from '@utils/loadable';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';

import withComponentName from '../../../decorators/withComponentName';
import withRouter from '../../../decorators/withRouter';

import Styles from './footerContacts.module.scss';

import Logo from '../../../assets/round-logo.png';

const cx = classNames.bind(Styles);

const FooterLink = loadable(() => import('../FooterLink'));

// for preventing twitter scripts default behavior
const onTwitterClick = (e, piwikEnabled) => {
  e.preventDefault();
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();

  if (piwikEnabled) _paq.push(['trackEvent', 'Footer', 'Twitter']);

  window.open(e.currentTarget.href, '_blank');
};

const FooterContacts = ({
  contacts, trackUserClick, piwikEnabled, isRailsContext, location,
}) => {
  const mainSectionClass = cx('company-info-footer',
    { 'api-pricing-page': location.pathname.includes('/products/api/financial-news/pricing-api/') });

  return (
    <section className={mainSectionClass}>
      <div className={Styles['footer-left-background']} />
      <div className={Styles['company-info-inner']}>
        <div className={Styles['footer-logo']}>
          {isRailsContext ? (
            <a href="/">
              <img
                className={Styles['footer-round-logo']}
                src={Logo}
                alt="logo"
                loading="lazy"
                width={41}
                height={41}
              />
            </a>
          ) : (
            <Link to="/">
              <img
                className={Styles['footer-round-logo']}
                src={Logo}
                alt="logo"
                loading="lazy"
                width={41}
                height={41}
              />
            </Link>
          )}
          <span>
            ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            City Falcon Limited
          </span>
        </div>
        <p>UK Company Registration Number 09107763</p>
        <p>Level39, One Canada Square, Canary Wharf, London E14 5AB</p>
        <div className={Styles['company-links-footer']}>
          <div className={Styles['social-links-footer']}>
            <a
              onClick={() => trackUserClick('Facebook')}
              href="https://www.facebook.com/cityfalconfollow"
              aria-label="Follow on facebook"
              className={Styles['facebook-link-footer']}
              target="_blank"
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://twitter.com/intent/user?screen_name=CityFalcon"
              aria-label="Follow on X"
              onClick={(e) => onTwitterClick(e, piwikEnabled)}
              className={Styles['twitter-link-footer']}
            >
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
          </div>
          {contacts.map((item) => (
            <FooterLink
              key={item.title}
              {...item}
              footerContacts
              trackUserClick={trackUserClick}
              isRailsContext={isRailsContext}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default withComponentName(withRouter(FooterContacts));
