import { useLocation } from 'react-router-dom';

import HeaderComponent from './HeaderComponent';

import withComponentName from '../../decorators/withComponentName';

import { isRailContextPages } from '../../data/webPageData';

function Header(props) {
  const { railsContext } = props;
  const location = useLocation();

  const path = location?.pathname || railsContext?.pathname || '';

  const showProductsPagesLeftbar = [
    '/financial-enterprise-solutions',
  ].includes(path);

  const pagePath = path.split('/')[1] || '';
  const isRailsContext = Boolean(isRailContextPages[pagePath]);

  return (
    <HeaderComponent
      {...props}
      path={path}
      showProductsPagesLeftbar={showProductsPagesLeftbar}
      railsContext={railsContext}
      isRailsContext={isRailsContext}
    />
  );
}

export default withComponentName(Header);
