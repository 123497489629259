import classNames from 'classnames/bind';

import withComponentName from '../../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const CTAbuttons = ({
  fromMobileMenu,
  closeMenu,
  path,
  ctaButtonType,
}) => {
  const closeMobileMenu = () => {
    if (fromMobileMenu) {
      closeMenu();
    }
  };

  const showTryOurApi = path !== '/products/api/financial-news' && path.substr(0, 36) !== '/products/api/financial-news/pricing';

  const CTAButtonsClass = cx('cta-buttons', {
    'with-try-api': showTryOurApi,
    'financial-news-cta': '/products/api/financial-news'.indexOf(path) !== -1,
    'cta-anchor-button': ctaButtonType === 'mobileAnchorButton',
  });

  return (
    <div className={CTAButtonsClass}>
      {showTryOurApi && (
        <a
          className={cx('cta-buttons--try-api')}
          href="/products/api/financial-news"
          onClick={closeMobileMenu}
        >
          Try Our API
        </a>
      )}
    </div>
  );
};

export default withComponentName(CTAbuttons);
