import classNames from 'classnames/bind';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const ChatbotBanner = ({
  children, chatbotBannerIsActive, closeChatbotBanner, chatbotBannerAnimation,
}) => (
  <div className={cx('banner',
    {
      'banner-close-animation': chatbotBannerAnimation,
      'banner-hidden': !chatbotBannerIsActive,
    })}
  >
    {chatbotBannerIsActive ? (
      <>
        <div className={cx('banner__header')}>
          <div className={cx('banner__title')}>
            Meet Our
            {' '}
            <span>AI Assistant</span>
            <span className={cx('banner__sparkles-icon')}>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.7734 3.85938L15 3L15.8203 0.8125C15.8594 0.617188 16.0547 0.5 16.25 0.5C16.4062 0.5 16.6016 0.617188 16.6406 0.8125L17.5 3L19.6875 3.85938C19.8828 3.89844 20 4.09375 20 4.25C20 4.44531 19.8828 4.64062 19.6875 4.67969L17.5 5.5L16.6406 7.72656C16.6016 7.88281 16.4062 8 16.25 8C16.0547 8 15.8594 7.88281 15.8203 7.72656L15 5.5L12.7734 4.67969C12.5781 4.64062 12.5 4.44531 12.5 4.25C12.5 4.09375 12.5781 3.89844 12.7734 3.85938ZM6.875 3.39062C6.95312 3.15625 7.1875 3 7.42188 3C7.65625 3 7.89062 3.15625 8.00781 3.39062L10.0391 7.84375L14.4922 9.875C14.7266 9.99219 14.8828 10.2266 14.8828 10.4609C14.8828 10.6953 14.7266 10.9297 14.4922 11.0469L10.0391 13.0781L8.00781 17.5312C7.89062 17.7656 7.65625 17.9219 7.42188 17.9219C7.1875 17.9219 6.95312 17.7656 6.875 17.5312L4.80469 13.0781L0.351562 11.0469C0.117188 10.9297 0 10.6953 0 10.4609C0 10.2266 0.117188 9.99219 0.351562 9.875L4.80469 7.84375L6.875 3.39062ZM6.48438 8.625C6.32812 9.01562 5.97656 9.36719 5.58594 9.52344L3.59375 10.4609L5.58594 11.3984C5.97656 11.5547 6.32812 11.9062 6.48438 12.2969L7.42188 14.2891L8.35938 12.2969C8.51562 11.9062 8.86719 11.5547 9.25781 11.3984L11.25 10.4609L9.25781 9.52344C8.86719 9.36719 8.51562 9.01562 8.35938 8.625L7.42188 6.63281L6.48438 8.625ZM15.8203 13.3125C15.8594 13.1172 16.0547 13 16.25 13C16.4062 13 16.6016 13.1172 16.6406 13.3125L17.5 15.5L19.6875 16.3594C19.8828 16.3984 20 16.5938 20 16.75C20 16.9453 19.8828 17.1406 19.6875 17.1797L17.5 18L16.6406 20.2266C16.6016 20.3828 16.4062 20.5 16.25 20.5C16.0547 20.5 15.8594 20.3828 15.8203 20.2266L15 18L12.7734 17.1797C12.5781 17.1406 12.5 16.9453 12.5 16.75C12.5 16.5938 12.5781 16.3984 12.7734 16.3594L15 15.5L15.8203 13.3125Z" fill="url(#paint0_linear_3_2117)" />
                <defs>
                  <linearGradient id="paint0_linear_3_2117" x1="16" y1="2.5" x2="6" y2="13.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3A72C2" />
                    <stop offset="1" stopColor="#A72FD2" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
          </div>
          <svg
            className={cx('banner__times-icon')}
            width="20"
            height="21"
            viewBox="0 0 20 21"
            xmlns="http://www.w3.org/2000/svg"
            onClick={closeChatbotBanner}
          >
            <path
              d="M6.83594 7.33594C7.1875 6.98438 7.77344 6.98438 8.125 7.33594L9.96094 9.21094L11.8359 7.33594C12.1875 6.98438 12.7734 6.98438 13.125 7.33594C13.5156 7.72656 13.5156 8.3125 13.125 8.66406L11.2891 10.5L13.125 12.3359C13.5156 12.7266 13.5156 13.3125 13.125 13.6641C12.7734 14.0547 12.1875 14.0547 11.8359 13.6641L9.96094 11.8281L8.125 13.6641C7.77344 14.0547 7.1875 14.0547 6.83594 13.6641C6.44531 13.3125 6.44531 12.7266 6.83594 12.3359L8.67188 10.5L6.83594 8.66406C6.44531 8.3125 6.44531 7.72656 6.83594 7.33594ZM20 10.5C20 16.0469 15.5078 20.5 10 20.5C4.45312 20.5 0 16.0469 0 10.5C0 4.99219 4.45312 0.5 10 0.5C15.5078 0.5 20 4.99219 20 10.5ZM10 2.375C5.50781 2.375 1.875 6.04688 1.875 10.5C1.875 14.9922 5.50781 18.625 10 18.625C14.4531 18.625 18.125 14.9922 18.125 10.5C18.125 6.04688 14.4531 2.375 10 2.375Z"
            />
          </svg>
        </div>
        <div className={cx('banner__body')}>
          <ul className={cx('banner__benefits')}>
            <li className={cx('banner__benefits-option')}>
              <CheckedIcon />
              <span>
                Access all of our features through the chatbot
              </span>
            </li>
            <li className={cx('banner__benefits-option')}>
              <CheckedIcon />
              <span>
                Enjoy a personalised experience based on your preferences
              </span>
            </li>
            <li className={cx('banner__benefits-option')}>
              <CheckedIcon />
              <span>
                Interact with charts and other visual components
              </span>
            </li>
          </ul>
          <div
            onClick={closeChatbotBanner}
            className={cx('banner__footer')}
          >
            <Link
              to="/chatbot"
              className={cx('banner__start-button')}
            >
              Start Chatting
            </Link>
            {children}
          </div>
        </div>
      </>
    ) : null}
  </div>
);

const CheckedIcon = () => (
  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.3594 0.640625C17.8672 1.10938 17.8672 1.92969 17.3594 2.39844L7.35938 12.3984C6.89062 12.9062 6.07031 12.9062 5.60156 12.3984L0.601562 7.39844C0.09375 6.92969 0.09375 6.10938 0.601562 5.64062C1.07031 5.13281 1.89062 5.13281 2.35938 5.64062L6.46094 9.74219L15.6016 0.640625C16.0703 0.132812 16.8906 0.132812 17.3594 0.640625Z" fill="url(#paint0_linear_3_2104)" />
    <defs>
      <linearGradient id="paint0_linear_3_2104" x1="9" y1="-4" x2="9" y2="16" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3A72C2" />
        <stop offset="1" stopColor="#A72FD2" />
      </linearGradient>
    </defs>
  </svg>
);

export default ChatbotBanner;
