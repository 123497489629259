import { APIWithoutToken } from './api';
import { getAPICachingTime, returnAPICachingHeader } from '../../helpers/helpers';
import { cachingMedium } from '../../data/webPageData';

const cache = {};

export default async () => {
  try {
    if (cache.response && cache.expDate > Date.now()) {
      return cache.response;
    }

    const res = await APIWithoutToken.get('promobar', {
      headers: {
        'Cache-control': returnAPICachingHeader(cachingMedium),
      },
    });
    cache.response = res;
    cache.expDate = getAPICachingTime(cachingMedium);

    return res;
  } catch (e) {
    console.error(e);
    return {
      data: { promo_bar_contents: [] },
    };
  }
};
