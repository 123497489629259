export const footerData = [
  {
    title: 'About Us',
    link: '/about',
    modal: '',
  },
  {
    title: 'Press',
    link: '/press',
    modal: '',
  },
  {
    title: 'Our Values',
    modal: 'values',
    useAnchor: true,
  },
  {
    title: 'Contact Us',
    link: '/contact-us',
    modal: '',
    useAnchor: true,
  },
];

export const footerConfig = {
  sections: [
    {
      title: 'Products',
      id: 1,
      links: [
        {
          title: 'Enterprise Financial Solutions',
          link: '/enterprise-products',
          modal: '',
          useAnchor: true,
          target: '_blank',
        },
        {
          title: 'Financial News API',
          link: '/products/api/financial-news',
          modal: '',
          useAnchor: true,
        },
        {
          title: 'iOS App',
          link: 'https://itunes.apple.com/us/app/cityfalcon/id1148560018?ls=1&mt=8',
          modal: '',
          useAnchor: true,
          target: '_blank',
        },
        {
          title: 'Android App',
          link: 'https://play.google.com/store/apps/details?id=com.cityfalcon',
          modal: '',
          useAnchor: true,
          target: '_blank',
        },
        {
          title: 'CityFalcon Voice',
          link: '/products/voiceassistant',
          useAnchor: true,
          modal: '',
        },
      ],
    },
    {
      title: 'LEGAL & HELP',
      id: 2,
      links: [
        {
          title: 'Privacy and Cookies Policy',
          link: '/privacy',
          modal: '',
        },
        {
          title: 'Terms of Service',
          link: '/terms',
          modal: '',
        },
        {
          title: 'Help Centre',
          link: 'https://help.cityfalcon.com/hc/en-us',
          modal: '',
          useAnchor: true,
        },
        {
          title: 'Video Tutorials',
          link: '/help-videos',
          modal: '',
        },
        {
          title: 'Language Coverage',
          link: 'https://www.cityfalcon.ai/features/language-coverage',
          modal: '',
          useAnchor: true,
        },
      ],
    },
    {
      title: 'OTHERS',
      id: 3,
      links: [
        {
          title: 'Directory',
          link: '/news/directory',
          modal: '',
        },
        {
          title: 'Blog',
          link: 'https://www.cityfalcon.ai/blog',
          modal: '',
          useAnchor: true,
        },
        {
          title: 'Pricing',
          link: '/pricing',
          modal: '',
        },
        {
          title: 'Value Investing Meetup',
          link: '/meetup',
          modal: '',
        },
        {
          title: 'Invest in CityFALCON',
          link: '/invest',
          modal: '',
          useAnchor: true,
        },
      ],
    },
  ],
};
