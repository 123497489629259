import { RetryAPICall } from '../../helpers/apiHelpers';
import API from './api';

export default class UserMessagesDbService {
  static async getUserMessages() {
    try {
      const { data } = await RetryAPICall(API, 'users/messages/next');
      return data;
    } catch {
      return {
        messages: [],
      };
    }
  }

  static async markUserMessageRead(id) {
    const { data } = await API.post(`users/messages/${id}/mark_as_read`);
    return data;
  }
}
