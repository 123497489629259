import { PureComponent } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';

import loadable from '../../utils/loadable';

import UserMessagesDbService from '../../services/dbServices/UserMessagesDbService';

import withComponentName from '../../decorators/withComponentName';

const SubscriptionPlanModal = loadable(() => import('./SubscriptionPlanModal'));

class UserMessagesService extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      showSubscriptionModal: false,
      data: {},
    };
  }

  async componentDidMount() {
    const { permissions } = this.props;

    if (permissions.states.new_messages) {
      const { messages } = await UserMessagesDbService.getUserMessages();
      this.setState({
        ...messages[0],
      }, this.showTemplate);
    }
  }

  async componentDidUpdate(prevProps) {
    const { permissions } = this.props;

    if (
      permissions.states.new_messages !== prevProps.permissions.states.new_messages
      && permissions.states.new_messages
    ) {
      const { messages } = await UserMessagesDbService.getUserMessages();
      if (messages[0]) {
        this.setState({
          ...messages[0],
        }, this.showTemplate);
      }
    }
  }

  // showing template/modal based on message type
  showTemplate() {
    const { type } = this.state;
    if (type === 'new_premium_plan') {
      this.setState({
        showSubscriptionModal: true,
      });
    }
  }

  // to mark message as read by user
  closeMessage(messageId) {
    UserMessagesDbService.markUserMessageRead(messageId);
  }

  render() {
    const { id, showSubscriptionModal, data } = this.state;
    return (
      <>
        {showSubscriptionModal && (
          <SubscriptionPlanModal
            show={showSubscriptionModal}
            planType={data.plan_key}
            planName={data.plan_name_upcase}
            id={id}
            markMessageRead={this.closeMessage}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  permissions: state.subscriptions.permissions,
});

export default withComponentName(
  connect(mapStateToProps, null)(UserMessagesService),
);
