import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import withComponentName from '../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const SwitchRouteContainer = (props) => {
  const { children } = props;
  const location = useLocation();

  const isHiddenHeaderSearchbar = useSelector(({ header }) => header.isHiddenHeaderSearchbar);
  const isOpenedChatbotWidgetView = useSelector(
    ({ chatbot }) => chatbot.isChatbotOpened && !chatbot.expanded,
  );
  const cookies = useSelector(({ common }) => common.cookiesPolicyStatus);
  const promobarHeight = useSelector(({ promobar }) => promobar.height);
  const isShowed = useSelector(({ promobar }) => promobar.isShowed);
  const promobarType = useSelector(({ promobar }) => promobar.promobarType);

  const classname = isHiddenHeaderSearchbar ? 'watchlist-header' : 'main-header';

  return (
    <div className={cx('switch-wrapper-route',
      { [classname]: true },
      { 'chatbot-page': location.pathname === '/chatbot' },
      { 'opened-chatbot-widget': isOpenedChatbotWidgetView },
      { cookies },
      { [`${promobarType}`]: isShowed && (promobarHeight > 0) })}
    >
      {children}
    </div>
  );
};

export default withComponentName(SwitchRouteContainer);
