import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import permissionsDecorator from '../../../decorators/permissionsDecorator';
import withComponentName from '../../../decorators/withComponentName';

import { SILVER } from '../../../data/permissions';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export function WatchlistUpdateSuggestion({
  permissionsInitialized,
  maxWatchlist,
  accessLevels,
  showSuggestion,
  basicStyles,
  manageWatchlists = false,
  bigScreen,
}) {
  return permissionsInitialized && !accessLevels.includes(SILVER) && (showSuggestion || manageWatchlists) && (
    <div className={cx('suggestion-block',
      { basic: basicStyles },
      { big_screen: bigScreen },
      { 'manage-wl-page': manageWatchlists })}
    >
      Your current plan allows&nbsp;
      {maxWatchlist}
      &nbsp;watchlists.&nbsp;
      <a href="/pricing">Upgrade</a>
      &nbsp;for more.
    </div>
  );
}

function mapToStateProps({ subscriptions, watchlistReducer }) {
  const { permissions, permissionsInitialized } = subscriptions;
  const { watchlistsList } = watchlistReducer;
  const {
    max_watchlists: maxWatchlist,
    access_levels: accessLevels,
  } = permissions;

  return {
    permissionsInitialized,
    maxWatchlist,
    accessLevels,
    showSuggestion: (watchlistsList.length >= maxWatchlist),
  };
}

export default withComponentName(
  connect(mapToStateProps)(permissionsDecorator(WatchlistUpdateSuggestion)),
);
