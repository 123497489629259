import { PureComponent, createRef } from 'react';
import { Link } from 'react-router-dom';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEqual from 'lodash-es/isEqual';
import { Modal } from 'react-bootstrap';
import pluralize from 'pluralize';
import ResizeObserver from 'resize-observer-polyfill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';

import * as searchActions from '../../../actions/searchActions';
import * as watchlistActions from '../../../actions/watchlistActions';
import * as preferencesActions from '../../../actions/preferencesActions';
import { setSelectedLocation } from '../../../actions/locationsSentiment.actions';

import loadable from '../../../utils/loadable';

import {
  getTopicGroup,
  piwikSearchbarTopicsFormatter,
} from '../../../helpers/helpers';
import { getUserToken } from '../../../helpers/getUserToken';
import { pickParams, getParamValueFromUrl } from '../../../helpers/commonHelpers';
import { formatTopicsQuery, isSpecialCharacters } from '../../../helpers/watchlistHelperFunctions';
import { createGuestWatchlist, getQueryType } from '../../../helpers/guestUsersWatchlists';
import { filterSelectedTimeFilter } from '../../../helpers/timeFilterHelpers';
import { filterSelectedSentimentForStories } from '../../../helpers/storyHelpers';
import { buildAdvancedSearchQuery, isQueryValid } from '../../../helpers/searchbarHelpers';
import { createNewPrivateCompaniesForWatchlist } from '../../../helpers/topicsApiHelpers';

import SearchbarStateContext from '../../../contexts/SearchbarStateContext';
import withComponentName from '../../../decorators/withComponentName';
import permissionsDecorator from '../../../decorators/permissionsDecorator';
import withRouter from '../../../decorators/withRouter';

import StorageSvc from '../../../services/StorageSvc';
import EntitiesSvc from '../../../services/dbServices/EntitiesSvc';

import Alert from '../../Shared/ReactAlert';
import SearchbarInput from './SearchbarInput';
import BluePreloader from '../../GeneralComponents/BluePreloader';
import AdvancedSearchOperators from '../AdvancedSearchOperators';
import Tooltip from '../../Tooltip';

import { SILVER } from '../../../data/permissions';
import {
  ADVANCED_SEARCH,
  BASIC_SEARCH,
  SEARCH_TYPES_LIST,
  searchTypeListMapping,
  searchTypeListWatchlistMapping,
} from '../../../data/searchbar/types';

import Styles from './styles.module.scss';
import NotificationWatchlistTopicsComponent from '../../PreferencesComponents/NotificationsComponents/NotificationsTopStories/NotificationWatchlistTopicsComponent';
import Scrollable from '../../Shared/Scrollable';
import WatchlistUpdateSuggestion from '../../SubscriptionsComponents/WatchlistUpdateSuggestion';
import NameWatchlistModal from '../../WatchlistComponents/WatchlistManagePageWrapper/NameWatchlistModal';

const SearchBarDropDown = loadable(() => import('../SearchbarDropDown'));

const cx = classNames.bind(Styles);

// Parent component for searchbar.
// Possible props for configure styles, logic and pass data for different pages are:
// - oneItem: one item searchbar styles(used when user can choose 1 topic/query)
// - items - selected topic item(for one item searchbar)
// - multipleSearch - searchbar styles for multiple topics selection
// - newWatchlistTopics - selected(in searchbar) watchlist topics for new wl creation
// - userQuerySearch - user custom query
// - onboarding - onboarding page searchbar
// - hiddenHeaderSearchbar - styles for hidden searchbar in header(on watchlist page or trending)
// - submitAddText - text in searchbar submit button is "ADD"

export class SearchbarInner extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.searchWrapper = createRef();
    this.tooltip = createRef();
    this.inputContentRef = createRef();
    this.mounted = true;
    this.state = {
      inputValue: '',
      blur: false,
      focus: false,
      activeSearch: false,
      warningModalOpened: false,
      warningAfterCloseEvent: false,
      outsideClickModalOpened: false,
      placeholderValue: ' ',
      inputContentHeight: 0,
      modalNameWl: false,
    };
    this.resizeObserver = null;
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.focusSearch && nextProps.searchbarType === 'headerSearchbar') {
      return {
        activeSearch: true,
      };
    }
    return null;
  }

  componentDidMount() {
    const {
      actions, searchbarType, manageWatchlistsPage,
      openedManageWatchlistsCreateSearchbar,
    } = this.props;
    const { activeSearch } = this.state;

    document.addEventListener('keydown', this.escFunction, false);

    actions.removeSearchbarResults();
    actions.setUserToken(getUserToken());
    this.checkPassedQueriesInUrl();

    if (searchbarType === 'searchPageSearchbar') this.getTopicFromStorage();

    if (activeSearch) this.handleFocusChange();
    if (manageWatchlistsPage && openedManageWatchlistsCreateSearchbar) {
      this.handleFocusChange();
    }
    this.mounted = true;

    if (searchbarType === 'homeSearchbar') {
      this.resizeObserver = new ResizeObserver(() => {
        if (!this.mounted) return;
        this.setState({
          inputContentHeight: this.inputContentRef?.current?.clientHeight || 0,
        });
      });
      this.resizeObserver.observe(this.inputContentRef.current);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      items,
      newWatchlistTopics,
      searchbarType,
      location,
      activeSearchType,
      searchBarActive,
      storyFilters: { conflictsChecked },
    } = this.props;
    const {
      storyFilters: {
        conflictsChecked: prevConflictsChecked,
      },
    } = prevProps;
    const { activeSearch } = this.state;
    const state = {};

    if (
      conflictsChecked !== prevConflictsChecked
      || !isEqual(items, prevProps.items)
      || !isEqual(newWatchlistTopics, prevProps.newWatchlistTopics)
    ) {
      if ((activeSearch || searchbarType === 'searchPageSearchbar') && (items?.length || newWatchlistTopics?.length)) {
        Object.assign(state, {
          inputValue: '',
          placeholderValue: '',
        });
      }

      if (
        conflictsChecked !== prevConflictsChecked
        || prevProps.items?.length < items?.length
        || prevProps.newWatchlistTopics?.length < newWatchlistTopics?.length
      ) {
        Object.assign(state, {
          focus: false,
          blur: true,
        });

        if (
          items?.[0]
          && searchbarType === 'searchPageSearchbar'
          && (
            conflictsChecked !== prevConflictsChecked
            || prevProps.items?.length < items?.length
            || activeSearchType !== prevProps.activeSearchType
            || searchBarActive !== prevProps.searchBarActive
          )
        ) {
          let query = '';
          if (activeSearchType === BASIC_SEARCH) {
            query = typeof items[0] === 'object' ? formatTopicsQuery(items) : items[0];
          }
          this.getStories(query);
        }
      }

      this.setState((prevState) => ({ ...prevState, ...state }));
    }
    if (searchbarType === 'headerSearchbar' && location && location.pathname !== prevProps?.location?.pathname) {
      this.cancelSearch(null, true);
    }
  }

  componentWillUnmount() {
    const { actions, searchbarType } = this.props;
    const { modalNameWl } = this.state;
    if (!['headerSearchbar', 'homeSearchbar'].includes(searchbarType)) {
      actions.falseSearchbarState(searchbarType);
    }
    this.mounted = false;
    document.removeEventListener('keydown', this.escFunction);
    document.removeEventListener('click', this.closeOnOutsideClick);
    if (!modalNameWl) this.cancelSearch(null, true);
    this.resizeObserver?.disconnect();
  }

  // activation of searchbar
  // eslint-disable-next-line react/sort-comp
  handleFocusChange() {
    const { focus } = this.state;
    const {
      actions,
      onboarding,
      onSearchBarActivate,
      items,
      oneItem,
      searchbarType,
      multipleSearch,
      openedSearchbarType,
      activeSearchType,
      manageWatchlistsPage,
      openedManageWatchlistsHeaderSearchbar,
      isWatchlist,
    } = this.props;

    if (focus) return;

    actions.loadSearchTabInfo({
      tab: 'Top Results',
      searchQuery: '',
      updateTab: true,
      locationsOnly: searchbarType === 'locationSearchbar',
      isWatchlist,
    });
    document.addEventListener('click', this.closeOnOutsideClick, false);

    if (onboarding) onSearchBarActivate(true);
    if (searchbarType === 'locationSearchbar') {
      actions.setSelectedLocation(null);
    }

    this.setState(({ placeholderValue }) => ({
      activeSearch: true,
      placeholderValue: ['headerSearchbar', 'homeSearchbar'].includes(searchbarType) ? '' : placeholderValue,
      focus: true,
      blur: false,
    }), () => {
      actions.toggleSearchState(false);
      if (oneItem && activeSearchType === BASIC_SEARCH) {
        this.setState({
          inputValue: searchbarType !== 'locationSearchbar' && items?.length ? (
            items[0]?.name || items[0] || ''
          ) : '',
        });
        actions.removeSearchbarResults();
      } else if (multipleSearch) {
        actions.loadSearchTabInfo({
          tab: 'Top Results',
          searchQuery: '',
          updateTab: true,
          locationsOnly: searchbarType === 'locationSearchbar',
          isWatchlist,
        });
      }
      if (searchbarType === 'homeSearchbar'
          || searchbarType === 'manageWatchlistsSearchbar'
          || searchbarType === 'editWatchlistsSearchbar'
          || searchbarType === 'notificationsSearchbar'
          || searchbarType === 'createWatchlistsSearchbar'
          || searchbarType === 'headerSearchbar'
          || searchbarType === 'searchPageSearchbar') {
        document.body.classList.add(Styles['searchbar-overflow-custom']);
      }
    });

    if (searchbarType === 'headerSearchbar'
    && manageWatchlistsPage && !openedManageWatchlistsHeaderSearchbar) {
      return;
    }
    const currentType = openedSearchbarType.find((f) => f.type === searchbarType);
    if (!currentType || (currentType && !currentType.opened)) {
      actions.toggleSearchbar(searchbarType);
    }
  }

  // getting user's searched topic from localstorage on initial load of search page
  getTopicFromStorage() {
    const { actions } = this.props;
    try {
      const topicFromStorage = StorageSvc.getItem('searchbarInput');
      if (topicFromStorage) {
        const parsedItem = JSON.parse(topicFromStorage);
        this.setState({ placeholderValue: '' });
        const { type, value } = parsedItem || {};

        switch (type) {
          case BASIC_SEARCH:
            if (typeof value === 'object') {
              this.getStories(formatTopicsQuery([value]));
            } else {
              this.getStories(getTopicGroup(value));
            }

            actions.addSingleSearchItem(value);
            break;
          case ADVANCED_SEARCH:
            actions.restoreSearchbarItems({
              activeSearchType: type,
              items: value,
              searchBarActive: false,
            });
            setTimeout(this.getStories, 1, value?.[0]);
            break;
          default:
            break;
        }

        StorageSvc.removeItem('searchbarInput');
      }
    } catch (err) {
      return undefined;
    }
  }

  // get stories on searchpage
  getStories(query, fullText = '') {
    const {
      storyFilters: filters,
      actions, earliestSearchDate, accessLevels,
    } = this.props;

    if (!filters.conflictsChecked) return;
    const hasSilverAccess = accessLevels.includes(SILVER);
    // getting main search result
    const params = pickParams(
      { ...filters },
      'categories', 'selected_time_filter', 'languages', 'translate_to',
      'blacklisted_domain_ids', 'whitelisted_domain_ids', 'domains_filtering_mode',
      ...filterSelectedTimeFilter(filters.selected_time_filter),
      ...filterSelectedSentimentForStories(filters, hasSilverAccess),
    );
    if (filters.all_languages) delete params.languages;

    params.all_languages = Boolean(filters.all_languages);
    params.premium_only = filters.premium_content_only;
    params.fold_similar_stories = filters.group_similar_stories;

    actions.setQuery(fullText || '');
    actions.searchStories({
      query,
      params,
      nextPage: false,
      earliestSearchDate,
    });
    actions.toggleSearchState(true);
  }

  // helper for setting submit button label text
  getSubmitText() {
    const {
      submitAddText,
      onboarding,
      width,
      manageWatchlistsPage,
      searchbarType,
    } = this.props;
    const { inputValue } = this.state;

    const itemsArr = this.wlPageAssets(searchbarType === 'createWatchlistsSearchbar'
    || searchbarType === 'manageWatchlistsSearchbar');

    const showText = Boolean(itemsArr?.length || inputValue?.trim()?.length);

    // on all resolutions show only icon for onboarding
    if ((searchbarType === 'createWatchlistsSearchbar'
    || searchbarType === 'manageWatchlistsSearchbar') && showText) return 'Create Watchlist';
    if (onboarding || manageWatchlistsPage) return '';

    if (submitAddText || (width >= 2800)) return 'Add';

    return 'Search';
  }

  getSubmitSearchButton() {
    const {
      multipleSearch,
      searchbarType,
      onboarding,
      width,
      manageWatchlistsPage,
    } = this.props;
    const { activeSearch, inputValue } = this.state;

    const itemsArr = this.wlPageAssets(searchbarType === 'createWatchlistsSearchbar'
    || searchbarType === 'manageWatchlistsSearchbar');

    const showText = Boolean(itemsArr?.length || inputValue?.trim()?.length);

    const inactiveHomePage = (searchbarType === 'homeSearchbar' || searchbarType === 'notificationsSearchbar') && !activeSearch;

    if (searchbarType !== 'locationSearchbar') {
      if ((onboarding || manageWatchlistsPage) && (width > 1024) && showText) {
        return (
          <div
            className={cx('button_submit', { 'active-search': showText })}
            onClick={this.searchSubmit}
          >
            <span
              className={cx('button_submit__text')}
            >
              {this.getSubmitText()}
            </span>
          </div>
        );
      } if (onboarding || manageWatchlistsPage
        || (searchbarType === 'createWatchlistsSearchbar' && width < 1024)) {
        return (
          <div
            className={cx('button_submit', { 'active-search': showText })}
            onClick={this.searchSubmit}
          >
            <FontAwesomeIcon className={cx('button_submit__icon')} icon={faSearch} />
          </div>
        );
      }

      if (activeSearch || (multipleSearch && searchbarType !== 'editWatchlistsSearchbar')
        || ['searchPageSearchbar', 'homeSearchbar', 'locationSearchbar'].includes(searchbarType)) {
        return (
          <div
            className={cx('button_submit')}
            onClick={this.searchSubmit}
          >
            <span
              className={!inactiveHomePage ? cx('desktop-only') : ''}
            >
              {this.getSubmitText()}
            </span>
            {!inactiveHomePage && (
              <FontAwesomeIcon className={cx('mobile-only')} icon={faSearch} />
            )}
          </div>
        );
      }
    }
  }

  setInputValue(text) {
    this.setState({ inputValue: text });
  }

  // closing searchbar on ESC
  escFunction(event) {
    if (event.keyCode === 27) {
      this.cancelSearch(null, true);
    }
  }

  emptySearchbar(e) {
    if (e) {
      e.stopPropagation();
      e.nativeEvent.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }

    const { actions } = this.props;

    this.removeSearchText(true);
    actions.removeSearchbarResults();
    actions.toggleSearchState(false);
  }

  // canceling search results, closing search input
  cancelSearch(e = null, forceClose = false, closeModalNameWl = false) {
    const {
      actions,
      newWatchlistTopics,
      searchbarType,
      activeWatchlistTopics,
      maxWatchlistSize,
      items,
      onboarding,
      openedSearchbarType,
      activeSearchType,
      manageWatchlistsPage,
      openedManageWatchlistsHeaderSearchbar,
      onSearchBarActivate,
      isWatchlist,
    } = this.props;
    const { outsideClickModalOpened, activeSearch, modalNameWl } = this.state;

    if (closeModalNameWl) {
      this.setState({ modalNameWl: false });
    }

    let cancelButton = false;
    if (e !== null) {
      cancelButton = e?.target.classList.contains('button_cancel');
    }

    if ((searchbarType === 'manageWatchlistsSearchbar' && manageWatchlistsPage)) {
      if (!e?.target || cancelButton) actions.toogleManageWatchlistsCreateSearchbar(false);
    }

    if ((searchbarType === 'headerSearchbar' && openedManageWatchlistsHeaderSearchbar)) {
      actions.toogleManageWatchlistsHeaderSearchbar(false);
    }

    const { warningModalOpened, warningAfterCloseEvent } = this.state;
    if ((warningModalOpened || warningAfterCloseEvent)) {
      this.setState({
        warningAfterCloseEvent: false,
      });
      return;
    }

    if (
      this.mounted && activeSearch
      && searchbarType === 'searchPageSearchbar'
      && activeSearchType === ADVANCED_SEARCH
      && items?.length
    ) {
      this.toggleOutsideClickModal();

      if (!outsideClickModalOpened) {
        return;
      }
    }
    // dont have to removeSearxhbarResults on manageWatchlistsPage
    if ((searchbarType === 'manageWatchlistsSearchbar' && manageWatchlistsPage)) return;

    if (onSearchBarActivate) onSearchBarActivate(false);

    const currentType = openedSearchbarType.find((f) => f.type === searchbarType);
    if (currentType?.opened) {
      // will do it in another place after create wl in onboarding page
      if ((!onboarding || cancelButton) && searchbarType !== 'locationSearchbar') {
        actions.removeSearchbarResults();
      }

      if (!onboarding || cancelButton) this.removeSearchText(true);

      actions.blurSearchbar(false);
      actions.focusSearchbar(false);

      actions.falseSearchbarState(searchbarType);
      if (!forceClose) {
        actions.loadSearchTabInfo({
          tab: 'Top Results',
          searchQuery: '',
          updateTab: true,
          locationsOnly: searchbarType === 'locationSearchbar',
          isWatchlist,
        });
      } else {
        actions.resetTabsDetails();
      }

      // will do it in another place after create wl in onboarding page
      if (!onboarding || cancelButton) {
        actions.addKeyword('');
        actions.setQuery('');
      }

      if (searchbarType === 'homeSearchbar'
          || searchbarType === 'manageWatchlistsSearchbar'
          || searchbarType === 'editWatchlistsSearchbar'
          || searchbarType === 'notificationsSearchbar'
          || searchbarType === 'createWatchlistsSearchbar'
          || searchbarType === 'headerSearchbar'
          || searchbarType === 'searchPageSearchbar') {
        document.body.classList.remove(Styles['searchbar-overflow-custom']);
      }
    }

    this.setState({
      activeSearch: false,
      focus: false,
      blur: true, // remove input sign in searchbar
    });

    document.removeEventListener('click', this.closeOnOutsideClick);
    if (e) e.stopPropagation();
  }

  closeSearchDropDown() {
    const {
      actions: { blurSearchbar }, searchbarType,
      actions, searchBarActive,
    } = this.props;

    blurSearchbar(false);
    this.setState({
      activeSearch: false,
      blur: true,
      focus: false,
    });
    if (searchbarType === 'homeSearchbar' || searchbarType === 'searchPageSearchbar') {
      document.body.classList.remove(Styles['searchbar-overflow-custom']);
    }
    if (searchbarType === 'searchPageSearchbar' && searchBarActive) {
      actions.toggleSearchbarState();
    }
  }

  // backspace items remove in search input
  removeSearchText(isForcedRemove = false) {
    const {
      items,
      newWatchlistTopics,
      placeholderAddText,
      width,
      searchbarType,
    } = this.props;
    if (!this.mounted) return;

    let placeholderValue;
    if (!placeholderAddText) {
      placeholderValue = !(items?.length || newWatchlistTopics?.length) ? `Search for financial securities, topics${width >= 426 ? ', countries, sectors' : ''}...` : '';
      if (searchbarType === 'notificationsSearchbar' && isForcedRemove) {
        placeholderValue = 'Start searching here';
      }
    } else {
      placeholderValue = 'Add topics';
    }

    this.setState({
      placeholderValue,
      inputValue: '',
    });
  }

  checkWidth() {
    const {
      fullWidth,
      searchbarType,
      hiddenHeaderSearchbar,
      width,
      showSearchBarIcon,
    } = this.props;
    const { activeSearch } = this.state;

    // calculate correct value for left side of opened searchbar to make it centered
    let position;
    if (this.searchWrapper.current) {
      const rect = this.searchWrapper.current.getBoundingClientRect();
      position = rect.right - 190;
    }

    if (
      (!hiddenHeaderSearchbar && searchbarType === 'headerSearchbar' && width <= 1250)
      || width <= 1024
      || showSearchBarIcon
    ) return '100%';
    if (activeSearch && fullWidth) return `${position}px`;
  }

  // return new watchlist topics or topics items for active watchlist (editing)
  wlPageAssets(newWatchlistTopic) {
    const { newWatchlistTopics, items } = this.props;

    return (newWatchlistTopic ? newWatchlistTopics : items) || [];
  }

  async addNewUserNotificationsTopics() {
    const {
      actions,
      items,
      subscriptionTopics,
      piwikEnabled,
      activeSearchType,
      firstSearchResult,
    } = this.props;
    const { inputValue } = this.state;

    if (
      activeSearchType === BASIC_SEARCH
      && (
        subscriptionTopics?.length === 50
        || items?.length > 50
        || (items?.length + subscriptionTopics?.length) > 50
      )
    ) {
      return Alert.info(
        'Currently you can not have more then 5 topics for your Top 50 Stories list',
        {
          position: 'top',
          effect: 'stackslide',
          timeout: 3000,
        },
      );
    }
    this.cancelSearch();

    let newInputValue = inputValue;

    const isRecommended = firstSearchResult?.recommended;

    if (inputValue && isRecommended) {
      items.push(firstSearchResult);
      newInputValue = '';
    }

    const newWatchlistTopics = await createNewPrivateCompaniesForWatchlist(items);

    if (piwikEnabled) _paq.push(['trackEvent', 'Notification', 'Selected Topics and Securities', piwikSearchbarTopicsFormatter(newWatchlistTopics)]);

    return actions.addTopicNotification(
      null,
      activeSearchType === BASIC_SEARCH
        ? formatTopicsQuery(newWatchlistTopics, newInputValue).split('"').join('')
        : buildAdvancedSearchQuery([
          ...newWatchlistTopics,
          ...(newInputValue?.trim() ? [newInputValue.trim()] : []),
        ]),
      null,
      subscriptionTopics,
    );
  }

  // submit search action, for searching topic, creating new wl, editing wl
  async searchSubmit(e) {
    if (e) e.stopPropagation();
    const {
      actions,
      items,
      multipleSearch,
      piwikEnabled,
      searchbarType,
      onboarding,
      activeSearchType,
      history,
      onSearchSubmit,
      manageWatchlistsPage,
      onSubmitManageWlPage,
      keyword, newWatchlistTopics,
      firstSearchResult,
      userQuerySearch,
      isWatchlist,
    } = this.props;

    if (userQuerySearch) return;

    const { inputValue } = this.state;

    const isRecommended = firstSearchResult?.recommended;

    if (activeSearchType === ADVANCED_SEARCH) {
      const items = this.wlPageAssets(searchbarType === 'createWatchlistsSearchbar' || searchbarType === 'manageWatchlistsSearchbar').slice();

      if (inputValue.trim()) {
        if (isRecommended) {
          items.push(firstSearchResult);
        } else {
          items.push({
            id: Math.floor(Math.random() * 1_000_000),
            name: inputValue.trim(),
            itemType: 'ENTITY',
          });
        }
      }

      if (!isQueryValid(items)) {
        this.tooltip.current?.showTooltip?.(e);
        return;
      }
    }

    if (searchbarType === 'locationSearchbar') {
      this.closeSearchDropDown();
      return;
    }

    if (onboarding) {
      if (onSearchSubmit) {
        if (inputValue.trim() && isRecommended) {
          actions.addNewWatchlistAssets(firstSearchResult);
          actions.addKeyword('');
          return;
        }
        onSearchSubmit();
      }
    } else if (manageWatchlistsPage) {
      if (onSubmitManageWlPage) {
        if (inputValue.trim() && isRecommended) {
          actions.addNewWatchlistAssets(firstSearchResult);
          actions.addKeyword('');
          return;
        }
        onSubmitManageWlPage({
          keyword,
          newWatchlistTopics,
        });
      }
    } else if (
      // close searchbar, on submit click, when there is no topics from new watchlist creation
      !this.wlPageAssets(searchbarType === 'createWatchlistsSearchbar' || searchbarType === 'manageWatchlistsSearchbar').length
      && !inputValue
      && searchbarType !== 'searchPageSearchbar'
    ) {
      return this.cancelSearch();
    }

    // add user subscription topics on notifications page
    if (multipleSearch && searchbarType === 'notificationsSearchbar') return this.addNewUserNotificationsTopics();

    this.setState({ focus: false, blur: true });

    if (searchbarType === 'searchPageSearchbar') document.removeEventListener('click', this.closeOnOutsideClick);

    // search stories on search page
    if (searchbarType === 'searchPageSearchbar' && (items?.length || inputValue)) {
      if (inputValue) {
        const item = firstSearchResult;

        if (isRecommended && activeSearchType === BASIC_SEARCH) {
          if (!item.permalink && item.groups?.[0]) {
            item.permalink = (await EntitiesSvc.getEntityPermalink({
              entityType: item.groups[0].groupType,
              entityId: item.groups[0].groupName,
            }, false, actions)).permalink;
          }
          if (item?.permalink) {
            window.location.href = item.permalink;
            return;
          }
        }

        if (isRecommended && activeSearchType === ADVANCED_SEARCH) {
          actions.addSingleSearchItem(item);
          return;
        }

        actions.addSingleSearchItem({
          id: Math.floor(Math.random() * 1_000_000_000),
          name: inputValue,
          itemType: 'ENTITY',
        });
      }

      this.getStories(getTopicGroup(items[0] || inputValue));

      actions.loadSearchTabInfo({
        tab: 'Top Results',
        searchQuery: '',
        updateTab: true,
        locationsOnly: searchbarType === 'locationSearchbar',
        isWatchlist,
      });
      actions.addKeyword('');
      this.closeSearchDropDown();

      if (piwikEnabled) _paq.push(['trackEvent', 'Search', 'Search GO']);

      // search topics in header search and redirect user to search page
    } else if (
      ['headerSearchbar', 'homeSearchbar'].includes(searchbarType)
      && (
        (activeSearchType === BASIC_SEARCH && inputValue && !items?.length)
        || (activeSearchType === ADVANCED_SEARCH && (items?.length || inputValue.trim()))
      )
    ) {
      let value;
      if (activeSearchType === BASIC_SEARCH) {
        // redirecting user to SEO page if the first search result is recommended
        if (isRecommended && !firstSearchResult.permalink) {
          if (firstSearchResult.groups?.[0]) {
            firstSearchResult.permalink = (await EntitiesSvc.getEntityPermalink({
              entityType: firstSearchResult.groups[0].groupType,
              entityId: firstSearchResult.groups[0].groupName,
            }, false, actions)).permalink;
          }
        }

        if (isRecommended && firstSearchResult?.permalink) {
          window.location.href = firstSearchResult.permalink;
          return;
        }
        value = inputValue.trim();
      } else {
        value = items;
        if (inputValue.trim()) {
          if (isRecommended) {
            actions.addSearchItem(firstSearchResult);
            actions.addKeyword('');
            return;
          }
          value.push({
            id: Math.floor(Math.random() * 1_000_000),
            name: inputValue.trim(),
            itemType: 'ENTITY',
          });
        }
      }

      StorageSvc.setItem(
        'searchbarInput',
        JSON.stringify({
          type: activeSearchType,
          value,
        }),
      );

      if (piwikEnabled) _paq.push(['trackEvent', 'Search', 'Search GO']);
      history.push('/search');
    } else if (
      inputValue
      && !items?.length
      && !multipleSearch
    ) {
      this.cancelSearch();

      // add topics to active watchlist on watchlist page
    } else if (multipleSearch && (searchbarType !== 'createWatchlistsSearchbar' && searchbarType !== 'manageWatchlistsSearchbar')) {
      this.editActiveWatchlist();

      // create new watchlist
    } else if (
      multipleSearch
      && searchbarType === 'createWatchlistsSearchbar'
      && (this.wlPageAssets(searchbarType === 'createWatchlistsSearchbar').length > 0 || inputValue.trim())
      && !onboarding
      && !manageWatchlistsPage
    ) {
      if (inputValue.trim() && isRecommended) {
        actions.addNewWatchlistAssets(firstSearchResult);
        return;
      }

      this.setState({ modalNameWl: true });
    } else {
      this.cancelSearch();
      if (!onboarding && !manageWatchlistsPage) actions.removeSearchbarResults();
    }
  }

  // active watchlist editing
  async editActiveWatchlist() {
    const { inputValue } = this.state;
    const {
      items: tempTopicsList,
      activeWatchlist,
      actions,
      token,
      piwikEnabled,
      activeWatchlistTopics,
      firstSearchResult,
      activeSearchType,
    } = this.props;

    const isRecommended = firstSearchResult?.recommended;

    let newInputValue = inputValue;
    if (inputValue && isRecommended) {
      tempTopicsList.push(firstSearchResult);
      newInputValue = '';
    }

    actions.updateWatchlistTopicsBegin();

    const msToRun = 2000; // 2 seconds
    const t0 = performance.now();
    let items = tempTopicsList;
    if (activeSearchType === BASIC_SEARCH || !token) {
      items = await createNewPrivateCompaniesForWatchlist(tempTopicsList);
    }

    if (piwikEnabled) _paq.push(['trackEvent', 'WL page', 'Add topic to WL', piwikSearchbarTopicsFormatter(items, newInputValue)]);

    const activeWlQueries = activeWatchlistTopics.map((wlTopic) => wlTopic.query);
    const updatedQuery = formatTopicsQuery(items, newInputValue).split(' ;; ').filter((query) => !activeWlQueries.includes(query)).join(' ;; ');
    if (updatedQuery) {
      if (token) {
        await actions.addNewWatchlistQueries(
          updatedQuery,
          activeWatchlist.id,
          token,
        );
      } else {
        await actions.addNewGuestQuery(activeWatchlist.id, items, newInputValue);
      }
    }
    if (performance.now() - t0 < msToRun) {
      await new Promise((resolve) => setTimeout(() => resolve(), performance.now() - t0));
    }
    actions.updateWatchlistTopicsSuccess();
    this.cancelSearch();
  }

  // general func for new watchlist creation
  async createNewWatchlist(closeSearch = true, watchlistName = '') {
    const {
      newWatchlistTopics: tempTopicsList,
      actions,
      token,
      onboarding,
      piwikEnabled,
      activeSearchType,
      firstSearchResult,
    } = this.props;
    let { inputValue } = this.state;
    inputValue = inputValue.trim();

    let newInputValue = inputValue;

    const isRecommended = firstSearchResult?.recommended;

    if (newInputValue && isRecommended) {
      tempTopicsList.push(firstSearchResult);
      newInputValue = '';
      return;
    }

    const msToRun = 2000; // 2 seconds
    const t0 = performance.now();

    let newWatchlistTopics = tempTopicsList;
    if (activeSearchType === BASIC_SEARCH || !token) {
      newWatchlistTopics = await createNewPrivateCompaniesForWatchlist(tempTopicsList);
    }

    // create new watchlist on watchlist page
    if (token && !onboarding && (newWatchlistTopics?.length || newInputValue?.length)) {
      const query = formatTopicsQuery(newWatchlistTopics, newInputValue);

      await actions.createNewWatchlist(query, token, watchlistName);

      if (piwikEnabled) _paq.push(['trackEvent', 'WL page', 'Create new WL', piwikSearchbarTopicsFormatter(newWatchlistTopics)]);
      // create watchlist for guest user
    } else if (newWatchlistTopics?.length || /[a-zA-Z]/g.test(newInputValue)) {
      // adding inputValue to handle case of user put query and hit enter
      actions.fetchStoriesBegin();

      if (piwikEnabled) _paq.push(['trackEvent', 'WL page', 'Create new WL', piwikSearchbarTopicsFormatter(newWatchlistTopics)]);

      await createGuestWatchlist({
        topics: newWatchlistTopics,
        userQuery: newInputValue,
        watchlistName,
        activeSearchType,
      }).then((res) => {
        if (res) actions.guestUserWatchlists();
      }).catch((err) => {
        console.log(err);
      });
    }

    if (performance.now() - t0 < msToRun) {
      await new Promise((resolve) => setTimeout(() => resolve(), performance.now() - t0));
    }

    actions.newWatchlistCreationSuccess();
    if (closeSearch) {
      actions.closeWatchlistDropDown();
      this.cancelSearch(null, false, true);
    }
  }

  // closing searchbar dropdown on clicking outside of it
  closeOnOutsideClick(e) {
    const { searchBarActive, searchbarType } = this.props;

    if (searchbarType === 'manageWatchlistsSearchbar'
    && document.getElementsByClassName('topic_user_warning_modal')[0]?.contains(e?.target)) {
      return;
    }

    if (searchbarType === 'createWatchlistsSearchbar'
    && document.getElementsByClassName('manage-wl-change-name-modal')[0]?.contains(e?.target)) {
      return;
    }

    if (
      (searchBarActive || searchbarType === 'editWatchlistsSearchbar')
      && !e.target.classList.contains('search-item__remove')
      && !this.searchWrapper.current?.contains(e.target)
      && this.mounted
    ) {
      this.cancelSearch(null, true);
    }
  }

  // This function responsible for set input
  // if there is ?q={} in url and load query search (SEARCH VIEW ONLY)
  checkPassedQueriesInUrl() {
    const { searchbarType } = this.props;

    let urlQuery = getParamValueFromUrl('q');

    if (urlQuery && searchbarType === 'searchPageSearchbar') {
      urlQuery = decodeURI(urlQuery).trim();

      this.setState({
        inputValue: urlQuery,
      }, () => {
        this.searchSubmit();
      });
    }
  }

  toggleWarningModal() {
    const { piwikEnabled } = this.props;

    this.setState(({ warningModalOpened }) => ({
      warningModalOpened: !warningModalOpened,
      warningAfterCloseEvent: warningModalOpened,
    }), () => {
      const { warningModalOpened } = this.state;
      if (warningModalOpened && piwikEnabled) _paq.push(['trackEvent', 'Premium', 'WL limit']);
    });
  }

  toggleOutsideClickModal(e) {
    e?.stopPropagation();
    e?.nativeEvent?.stopPropagation();
    const { outsideClickModalOpened } = this.state;

    if (!outsideClickModalOpened) {
      document.removeEventListener('click', this.closeOnOutsideClick);
    } else {
      document.addEventListener('click', this.closeOnOutsideClick);
    }

    this.setState({
      outsideClickModalOpened: !outsideClickModalOpened,
    });
  }

  // remove searchbar topics item from user selection in searchbar(multiple topics only)
  removeSearchItem({
    event,
    index,
    createNewWatchlist,
    item,
  }) {
    event.stopPropagation();
    const {
      actions, items, newWatchlistTopics, piwikEnabled,
    } = this.props;
    const searchItems = createNewWatchlist ? newWatchlistTopics : items;
    const leftSearchEntityIndex = index - 1;
    const rightSearchEntityIndex = index + 1;

    if (piwikEnabled) _paq.push(['trackEvent', 'Search', 'Remove selected entity', piwikSearchbarTopicsFormatter(item)]);

    actions.removeSearchItem({
      id: item.id,
      index,
      watchlistItems: createNewWatchlist,
    });

    const leftSearchEntity = searchItems.find((_, index) => index === leftSearchEntityIndex);
    const rightSearchEntity = searchItems.find((_, index) => index === rightSearchEntityIndex);

    // check items before removing
    if (index !== 0) {
      // if before entity has an OPERATOR then remove it.

      if (!leftSearchEntity) return;
      if (leftSearchEntity.itemType === 'ENTITY' || leftSearchEntity.itemType === 'AssetClass') return;

      actions.removeSearchItem({
        id: leftSearchEntity.id,
        index: leftSearchEntityIndex,
        watchlistItems: createNewWatchlist,
      });

      // if we have parenthese
      // previous element is left parenthese
      if (leftSearchEntity.name === '(') {
        // find & remove next search operator
        const nextSearchEntity = searchItems.find((_, index) => index === rightSearchEntityIndex);

        actions.removeSearchItem({
          id: nextSearchEntity.id,
          index: leftSearchEntityIndex,
          watchlistItems: createNewWatchlist,
        });

        // find right parethese, index & remove it.
        const rightParenthese = searchItems
          .find((item, index) => item.name === ')' && index > rightSearchEntityIndex);
        const rightParentheseIndex = searchItems
          .findIndex((item) => item.id === rightParenthese.id);

        actions.removeSearchItem({
          id: rightParenthese.id,
          index: rightParentheseIndex - 3,
          watchlistItems: createNewWatchlist,
        });
      }
      // previous element is right parenthese
      if (rightSearchEntity.name === ')') {
        // find & remove right parenthese operator
        const rightParenthese = searchItems.find((_, index) => index === rightSearchEntityIndex);
        actions.removeSearchItem({
          id: rightParenthese.id,
          index: leftSearchEntityIndex,
          watchlistItems: createNewWatchlist,
        });
      }
    } else {
      // if after entity has an OPERATOR then remove it.

      if (!rightSearchEntity) return;
      if (rightSearchEntity.itemType === 'ENTITY' || rightSearchEntity.itemType === 'AssetClass') return;

      actions.removeSearchItem({
        id: rightSearchEntity.id,
        index,
        watchlistItems: createNewWatchlist,
      });
    }
  }

  changeSearchbarType(e, searchType) {
    if (e) {
      e.stopPropagation();
      e.nativeEvent.stopPropagation();
    }
    const { actions, activeSearchType, handleSearchTypeChange } = this.props;
    if (activeSearchType !== searchType) {
      actions.setSearchbarType(searchType);
    }
    if (handleSearchTypeChange) handleSearchTypeChange(false);
  }

  getDropdownInlineStyles() {
    const { searchbarType, width } = this.props;
    const { inputContentHeight } = this.state;

    if (searchbarType === 'homeSearchbar' && width <= 767) {
      return { height: `calc(100% - 10px - ${inputContentHeight}px)` };
    } if (searchbarType === 'manageWatchlistsSearchbar' && width <= 767) {
      const keficient = Number(this.inputContentRef?.current?.clientHeight || 0) / 50;
      const height = 50 - (Number(keficient) * 4);
      return { height: `${height}svh`, overflow: 'unset', borderRadius: '0 0 10px 10px' };
    } if (searchbarType === 'manageWatchlistsSearchbar') {
      return { maxHeight: '70vh' };
    }
    return {};
  }

  handleConfirmWatchlistName(watchlistName) {
    const { actions } = this.props;

    actions.newWatchlistCreationBegin();
    this.createNewWatchlist(true, watchlistName);
  }

  render() {
    const {
      oneItem,
      items,
      keyword,
      multipleSearch,
      multipleFixed,
      newWatchlistTopics,
      userQuerySearch,
      onboarding,
      hiddenHeaderSearchbar,
      placeholderAddText,
      maxWatchlistSize,
      piwikEnabled,
      searchbarType,
      searchStyles,
      isFullLocations,
      width,
      isRailsContext,
      activeSearchType,
      headerWLShowBtn,
      searchBarActive,
      disabled,
      homepageHeader,
      location,
      hasBigScreenDesign,
      isNotificationPage,
      isWLType,
      handleSearchTypeChange,
      showSearch,
      maxWatchlists,
      watchlistsList,
      manageWatchlistsPage,
      hiddenHeader,
      accessLevels,
      isWatchlist,
    } = this.props;
    const {
      inputValue,
      placeholderValue,
      blur,
      focus,
      activeSearch,
      warningModalOpened,
      outsideClickModalOpened,
      modalNameWl,
    } = this.state;
    const hasSilverAccess = accessLevels.includes(SILVER);

    const itemsArr = this.wlPageAssets(searchbarType === 'createWatchlistsSearchbar' || searchbarType === 'manageWatchlistsSearchbar');
    const multipleTopics = multipleSearch || activeSearchType === ADVANCED_SEARCH;
    let isAdvancedQueryValid = false;
    if (activeSearchType === ADVANCED_SEARCH) {
      isAdvancedQueryValid = isQueryValid([
        ...itemsArr,
        ...(inputValue.trim() ? [{
          name: inputValue.trim(),
          itemType: 'ENTITY',
        }] : []),
      ]);
    }

    const disabledMaxLimitWl = watchlistsList.length >= maxWatchlists;

    const search = cx({
      search_bar: true,
      active: (activeSearch || itemsArr?.length),
      // add inline styles to searchbar when topics are present in closed state
      'inline-input-imitation': newWatchlistTopics?.length && !activeSearch && onboarding,
      // make submit button looks disabled if maxWatchlists limit
      'search_bar--disabled-wl-limit': disabledMaxLimitWl && onboarding,
      // make submit button looks disabled if nothing chosen/typed in input
      button_disabled: searchbarType !== 'homeSearchbar' && (
        (!inputValue?.trim().length && oneItem && items?.length < 1)
        || (!inputValue?.trim().length && oneItem && items?.length < 1)
      ),
      button_disabled_multiple: (
        !inputValue?.trim()?.length || isSpecialCharacters(inputValue?.trim())
      )
      && (
        (items?.length || newWatchlistTopics?.length) < 1
      ) && multipleSearch,
      button_disabled_invalid_query: !isAdvancedQueryValid,
      // styles for one item and multiple topic items in searchbar
      input_added_items: items?.length > 0 && ['headerSearchbar', 'homeSearchbar'].includes(searchbarType) && oneItem,
      input_added_items_multiple: (items?.length
        || newWatchlistTopics?.length) > 0 && multipleSearch,
      input_added_items_or_query_multiple: (items?.length
        || newWatchlistTopics?.length || inputValue?.trim()?.length) > 0 && multipleSearch,
      'hidden-header-search': hiddenHeaderSearchbar,
      full_location: isFullLocations,
      header_wl_btn: headerWLShowBtn,
      home_header: homepageHeader,
      not_home_header: hiddenHeader,
      'search-page': location.pathname.includes('search'),
      search_page_active: (location.pathname.includes('search') && activeSearch),
      big_screen: hasBigScreenDesign,
    }, ...searchStyles);

    const addedTopics = cx({
      added_search_items: true,
      multiple_topics: multipleTopics,
    });
    const cancelButonCn = `button_cancel ${cx('button_cancel', searchbarType === 'searchPageSearchbar' && 'search_page_cancel')}`;

    const searchbarWidth = this.checkWidth();
    // Removing input if any topic selected in header search bar
    const searchbar = !(['headerSearchbar', 'homeSearchbar', 'locationSearchbar'].includes(searchbarType) && items?.length && activeSearchType === BASIC_SEARCH) && (
      <SearchbarInput
        searchSubmit={this.searchSubmit}
        width={width}
        inputValue={inputValue}
        placeholderAddText={placeholderAddText}
        searchbarType={searchbarType}
        setInputValue={this.setInputValue}
        placeholderValue={placeholderValue}
        blur={blur}
        focus={focus}
        onboarding={onboarding}
        oneItem={oneItem}
        piwikEnabled={piwikEnabled}
        toggleWarningModal={this.toggleWarningModal}
        activeSearch={activeSearch}
        manageWatchlistsPage={manageWatchlistsPage}
        multipleSearch={multipleSearch}
        isWatchlist={isWatchlist}
      />
    );

    return (
      <SearchbarStateContext.Provider
        value={{
          openWithAnimation: Boolean(searchbarWidth && searchbarWidth !== '100%'),
        }}
      >
        <div className={search}>
          <div className={Styles.search_veil} />
          <div
            className={Styles.input_wrapper}
            style={{ width: searchbarWidth }}
            ref={this.searchWrapper}
          >
            <div className={cx('input_content', { notification: isWLType })} ref={this.inputContentRef}>
              {(
                searchbarType !== 'locationSearchbar'
                && (['searchPageSearchbar', 'createWatchlistsSearchbar', 'manageWatchlistsSearchbar', 'notificationsSearchbar'].includes(searchbarType) || activeSearch)
              ) && (
              <Scrollable
                disabled={width > 767}
                className={cx({ 'notifications-page': isNotificationPage }, { noScroll: !isNotificationPage })}
                isActive={(activeSearch || itemsArr?.length)}
              >
                <div className={Styles.searchbar_types}>
                  {SEARCH_TYPES_LIST.map((type) => (
                    <div
                      key={type.id}
                      className={cx('searchbar_type', { active: (activeSearchType === type.id && !isWLType) }, { notificationPage: isNotificationPage })}
                      onClick={(e) => this.changeSearchbarType(e, type.id)}
                    >
                      {type.content}
                    </div>
                  ))}
                  {isNotificationPage
                        && (
                        <div
                          className={cx('searchbar_type', { active: isWLType }, { notificationPage: isNotificationPage })}
                          onClick={() => handleSearchTypeChange(true)}
                        >
                          From your Watchlists
                        </div>
                        )}
                </div>
              </Scrollable>
              )}
              {!isWLType && (
              <div
                className={cx('input_imitation', { disabled }, { 'active-search': (activeSearch || itemsArr?.length) })}
                onClick={this.handleFocusChange}
              >
                {multipleTopics ? (
                  <div className={addedTopics}>
                    {Boolean(itemsArr?.length) && activeSearchType === ADVANCED_SEARCH && (
                    <div className={Styles.query_validation_status}>
                      {isAdvancedQueryValid ? (
                        <FontAwesomeIcon icon={faCheckCircle} />
                      ) : (
                        <Tooltip
                          icon={(
                            <FontAwesomeIcon
                              icon={faBan}
                              style={{
                                color: '#ef6c00',
                              }}
                            />
                            )}
                          ref={this.tooltip}
                        >
                          Your advanced search is incomplete. All operators require&nbsp;
                          at least one topic on each side. Parentheses and quotes&nbsp;
                          must be paired (opened and closed).
                        </Tooltip>
                      )}
                    </div>
                    )}
                    {(searchbarType === 'locationSearchbar' || searchbarType === 'searchPageSearchbar' || onboarding || searchBarActive) && itemsArr.map((item, index) => (
                      <div
                        className={cx('multiple_topics_item', item.itemType?.toLowerCase?.())}
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={`${item?.id || item?.name || item || ''}-${index}`}
                      >
                        <div className={Styles.multiple_topics_item_content}>
                          <div className={Styles.multiple_topics_item_title}>
                            {item?.name || item || ''}
                          </div>
                          {(item.itemType === 'ENTITY' || item.itemType === 'AssetClass') && (
                          <div className={Styles.multiple_topics_item_topic_class}>
                            {pluralize.singular(getQueryType(item))}
                          </div>
                          )}
                        </div>
                        <FontAwesomeIcon
                          icon={faXmark}
                          onClick={(event) => (
                            this.removeSearchItem({
                              event,
                              index,
                              createNewWatchlist: (searchbarType === 'createWatchlistsSearchbar' || searchbarType === 'manageWatchlistsSearchbar'),
                              item,
                            })
                          )}
                        />
                      </div>
                    ))}
                    {searchbar}
                  </div>
                ) : (
                  <div className={Styles.added_search_items}>
                    {items?.map((item, index) => (
                      <span
                        className={Styles.single_topic_item}
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                      >
                        {item?.name || item || ''}
                      </span>
                    ))}
                    {searchbar}
                  </div>
                )}

                {this.getSubmitSearchButton()}

                {searchbarType !== 'locationSearchbar' && activeSearch && (
                <div
                  className={cancelButonCn}
                  onClick={(e) => this.cancelSearch(e)}
                >
                  CANCEL
                </div>
                )}
                {searchbarType !== 'locationSearchbar' && Boolean(itemsArr?.length || keyword) && (
                  <FontAwesomeIcon className={cx('reset_searchbar')} icon={faXmark} onClick={this.emptySearchbar} />
                )}
              </div>
              )}
              {searchbarType !== 'locationSearchbar' && activeSearch && activeSearchType === ADVANCED_SEARCH && (
                <AdvancedSearchOperators
                  className={multipleSearch || ['headerSearchbar', 'homeSearchbar', 'searchPageSearchbar'].includes(searchbarType) ? Styles.multiple_search_operators : ''}
                  searchbarType={searchbarType}
                  onboarding={onboarding}
                />
              )}
            </div>
            {activeSearch && !isWLType && (
              <>
                <BluePreloader className={Styles.search_preloader} active={userQuerySearch} />
                <div
                  className={Styles.search_dropdown}
                  style={this.getDropdownInlineStyles()}
                >
                  <SearchBarDropDown
                    oneSearchItem={oneItem}
                    clearSearch={this.removeSearchText}
                    onboarding={onboarding}
                    closeSearch={this.closeSearchDropDown}
                    multipleSearchTab={multipleFixed}
                    piwikEnabled={piwikEnabled}
                    searchbarType={searchbarType}
                    activeSearchType={activeSearchType}
                    toggleWarningModal={this.toggleWarningModal}
                    cancelSearch={this.cancelSearch}
                    searchStyles={searchStyles}
                    hasBigScreenDesign={hasBigScreenDesign}
                    isWatchlist={isWatchlist}
                  />
                </div>
              </>
            )}
            {
              isWLType && showSearch && (
                <>
                  {searchbarType !== 'locationSearchbar' && activeSearch && (
                  <div
                    className={cancelButonCn}
                    onClick={(e) => this.cancelSearch(e)}
                  >
                    CANCEL
                  </div>
                  )}
                  <NotificationWatchlistTopicsComponent
                    piwikEnabled={piwikEnabled}
                    isActive={(activeSearch || itemsArr?.length)}
                  />
                </>
              )
            }
          </div>
          {onboarding && <WatchlistUpdateSuggestion basicStyles piwikEnabled={piwikEnabled} />}
        </div>
        <Modal
          show={warningModalOpened}
          onHide={this.toggleWarningModal}
          className={`topic_user_warning_modal ${cx('warning-modal', 'topic_user_warning_modal')}`}
          centered
        >
          <FontAwesomeIcon className={cx('close')} icon={faXmark} onClick={this.toggleWarningModal} />
          {hasSilverAccess ? (
            <>
              <div className={cx('message')}>
                You&apos;ve already added
                {` ${maxWatchlistSize} `}
                topics to this watchlist.
                Make another list or remove some topics.
              </div>
              <div className={Styles['warning-modal-footer']}>
                <button
                  type="button"
                  onClick={this.toggleWarningModal}
                  className={Styles['close-modal-btn']}
                >
                  OK
                </button>
              </div>
            </>
          ) : (
            <>
              <div className={cx('message')}>
                Your current plan allows&nbsp;
                {maxWatchlistSize}
                &nbsp;topics per watchlist. Upgrade for more.
              </div>
              <div className={Styles['warning-modal-footer']}>
                <button
                  type="button"
                  onClick={this.toggleWarningModal}
                >
                  Cancel
                </button>
                {isRailsContext ? (
                  <a href="/pricing">
                    See Plans
                  </a>
                ) : (
                  <Link to="/pricing">
                    See Plans
                  </Link>
                )}
              </div>
            </>
          )}
        </Modal>
        <Modal
          show={outsideClickModalOpened}
          onHide={this.toggleOutsideClickModal}
          className={cx('warning-modal')}
          centered
        >
          <FontAwesomeIcon className={cx('close')} icon={faXmark} onClick={this.toggleOutsideClickModal} />
          <div className={cx('message')}>
            Are you sure you don&#39;t want to save this selection of topics?
          </div>
          <div className={Styles['warning-modal-footer']}>
            <button
              type="button"
              onClick={this.toggleOutsideClickModal}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => this.cancelSearch(null, true)}
            >
              Leave
            </button>
          </div>
        </Modal>
        <NameWatchlistModal
          openModal={modalNameWl}
          handleConfirmNameWl={this.handleConfirmWatchlistName}
          watchlistsList={watchlistsList}
          handleCloseModal={() => {
            this.setState({ modalNameWl: false });
          }}
          handleBackButton={() => {
            this.setState({ modalNameWl: false });
          }}
          hasBigScreenDesign
        />
      </SearchbarStateContext.Provider>
    );
  }
}

SearchbarInner.defaultProps = {
  oneItem: false,
  isRailsContext: false,
  disabled: false,
  CTAButtons: 0, // number off buttons will be exist in the header
  onSearchSubmit: null,
};

const mapStateToProps = ({
  searchReducers,
  watchlistReducer,
  storyFilters,
  userPreferencesReducer,
  subscriptions,
  common,
}) => ({
  items: searchReducers[searchTypeListMapping[searchReducers.activeSearchType]],
  currentTab: searchReducers.activeTab,
  keyword: searchReducers.userKeywords,
  activeSearchType: searchReducers.activeSearchType,
  storyFilters,
  activeWatchlist: watchlistReducer.activeWatchlist,
  newWatchlistTopics: searchReducers[
    searchTypeListWatchlistMapping[searchReducers.activeSearchType]
  ],
  activeWatchlistTopics: watchlistReducer.activeWatchlistTopics,
  token: watchlistReducer.userToken,
  watchlistCreationBegin: watchlistReducer.watchlistCreationBegin,
  dropDownOpen: watchlistReducer.newWatchlistDropDown,
  userQuerySearch: searchReducers.userQuerySearch,
  searchBarActive: searchReducers.searchBarActive,
  openedManageWatchlistsCreateSearchbar: searchReducers.openedManageWatchlistsCreateSearchbar,
  openedManageWatchlistsHeaderSearchbar: searchReducers.openedManageWatchlistsHeaderSearchbar,
  focusSearch: searchReducers.focus,
  subscriptionTopics: userPreferencesReducer.subscriptionTopics,
  maxWatchlistSize: subscriptions.permissions.max_watchlist_size,
  maxWatchlists: subscriptions.permissions.max_watchlists,
  openedSearchbarType: searchReducers.openedSearchbarType,
  earliestSearchDate: subscriptions.permissions.earliest_search_date,
  accessLevels: subscriptions.permissions.access_levels,
  width: common.width,
  watchlistsList: watchlistReducer.watchlistsList,
  firstSearchResult: searchReducers.firstSearchResult,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...watchlistActions,
    ...searchActions,
    ...preferencesActions,
    setSelectedLocation,
  }, dispatch),
});

export default permissionsDecorator(
  withRouter(
    withComponentName(
      connect(
        mapStateToProps,
        mapDispatchToProps,
        null,
        { forwardRef: true },
      )(SearchbarInner),
    ),
  ),
);
