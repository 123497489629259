import { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';
import { isMobileOnly, isIOS, isIOS13 } from 'react-device-detect';
import { bindActionCreators } from 'redux';
import autoBind from 'react-autobind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch as faSearchRegular } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faSearch as faSearchSolid } from '@fortawesome/pro-solid-svg-icons/faSearch';

import * as searchActions from '../../../actions/searchActions';
import * as watchlistActions from '../../../actions/watchlistActions';

import { piwikSearchbarTopicsFormatter } from '../../../helpers/helpers';

import {
  BASIC_SEARCH,
  searchTypeListMapping,
  searchTypeListWatchlistMapping,
} from '../../../data/searchbar/types';

import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.module.scss';

export class SearchbarInput extends PureComponent {
  constructor(props) {
    super(props);
    this.input = createRef();
    this.timer = 0;
    autoBind(this);

    const { searchbarType } = props;
    this.initialPlaceholder = ['headerSearchbar', 'homeSearchbar'].includes(searchbarType) ? (
      'Search for financial securities, topics, countries, sectors...'
    ) : '';
  }

  componentDidMount() {
    this.changeInputAppearance();
  }

  componentDidUpdate(prevProps) {
    const {
      blur, focus, inputValue,
      newWatchlistTopics,
    } = this.props;

    this.changeInputAppearance();

    if (prevProps.inputValue !== inputValue) this.typeSearch(inputValue);

    if (blur) this.input.current.blur();

    // preventing automatically focus of input on mobile devices
    if (focus && !isMobileOnly) this.input.current.focus();

    if (!focus && !newWatchlistTopics?.length) {
      this.setPlaceholder();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onKeyEvent(e) {
    const {
      searchBarItems,
      newWatchlistTopics,
      actions,
      searchSubmit,
      piwikEnabled,
      searchbarType,
      isWatchlist,
    } = this.props;

    if (e.key === 'Backspace') {
      if (this.input.current.value === '') {
        if (newWatchlistTopics.length || searchBarItems.length) {
          const piwikItemsToSend = searchBarItems.length ? searchBarItems : newWatchlistTopics;

          if (piwikEnabled) {
            _paq.push([
              'trackEvent',
              'Search',
              'Remove selected entity',
              piwikSearchbarTopicsFormatter(piwikItemsToSend[piwikItemsToSend.length - 1]),
            ]);
          }

          actions.backspaceSearchItemRemove();

          actions.addKeyword('');
          actions.loadSearchTabInfo({
            tab: 'Top Results',
            searchQuery: '',
            updateTab: true,
            isWatchlist,
          });
        } else if (searchbarType === 'searchPageSearchbar') {
          this.changeSearchPageQuery();
        }
      }
    } else if (e.key === 'Enter') {
      searchSubmit();
    }
  }

  onInputFocus() {
    const { width, searchbarType } = this.props;

    const isCorrectSearchbar = ['homeSearchbar', 'notificationsSearchbar', 'createWatchlistsSearchbar'].includes(searchbarType);

    if (((isIOS || isIOS13) && width <= 1024 && isCorrectSearchbar)) {
      // To handle iphone / ipad keypade opening moving screen view issue
      setTimeout(() => window.scrollTo(0, 1), 100);
    }
  }

  setPlaceholder(text) {
    const {
      width,
      placeholderAddText,
      searchbarType,
      onboarding,
      placeholderNone,
      manageWatchlistsPage,
      multipleSearch,
      activeSearch,
    } = this.props;

    if (typeof text === 'string') {
      this.input.current.placeholder = text;
      return;
    }

    let placeholderValue = 'Search for financial securities, topics';
    if (
      (searchbarType === 'notificationsSearchbar' && width > 1280 && onboarding)
      || (searchbarType !== 'notificationsSearchbar' && width > 767 && (onboarding || manageWatchlistsPage || multipleSearch))
    ) {
      placeholderValue += ', sectors, locations, people, events';
    } else if (searchbarType !== 'notificationsSearchbar' && width > 767) {
      placeholderValue += ', countries, sectors';
    }
    placeholderValue += '...';

    if (searchbarType === 'notificationsSearchbar') {
      placeholderValue = 'Start searching here';
    }

    if (placeholderAddText) {
      placeholderValue = 'Add topics';
    } else if (searchbarType === 'locationSearchbar') {
      placeholderValue = 'Search for locations';
    } else if (placeholderNone) {
      placeholderValue = '';
    }

    if (activeSearch) placeholderValue = '';

    this.input.current.placeholder = placeholderValue;
  }

  typeSearch(text) {
    const {
      actions,
      setInputValue,
      piwikEnabled,
      activeWatchlistTopics,
      newWatchlistTopics,
      searchBarItems,
      maxWatchlistSize,
      toggleWarningModal,
      activeSearchType,
      searchbarType,
      currentTab,
      isWatchlist,
    } = this.props;

    if (
      activeSearchType === BASIC_SEARCH
      && ((
        activeWatchlistTopics.length + searchBarItems.length >= maxWatchlistSize
        && searchbarType === 'editWatchlistsSearchbar'
      ) || (newWatchlistTopics.length >= maxWatchlistSize))
      && text
    ) {
      return toggleWarningModal();
    }

    clearTimeout(this.timer);
    setInputValue(text);
    if (text) {
      actions.startLoadUserQuery();
    }

    this.timer = setTimeout(() => {
      if (piwikEnabled && text.length) _paq.push(['trackEvent', 'Search', 'Search query', text]);

      if (text) {
        actions.startLoadUserQuery();
        actions.addKeyword(text);
      } else {
        actions.addKeyword('');
      }

      const tab = currentTab === 'Topic Classes' ? 'Topic Classes' : 'Top Results';

      actions.loadSearchTabInfo({
        tab,
        searchQuery: text || '',
        updateTab: true,
        isWatchlist,
      });
    }, 300);
  }

  changeSearchPageQuery() {
    const { searchBarItems, actions, setInputValue, isWatchlist } = this.props;

    const newValue = searchBarItems[0]?.name || searchBarItems[0] || '';
    this.input.current.value = newValue;
    setInputValue(newValue);
    actions.loadSearchTabInfo({
      tab: 'Top Results',
      searchQuery: '',
      updateTab: true,
      isWatchlist,
    });
    actions.removeSearchbarResults();
  }

  changeInputAppearance() {
    const { placeholderValue, inputValue } = this.props;

    if (placeholderValue === '') {
      this.setPlaceholder('');
    } else {
      this.setPlaceholder();
    }
    this.input.current.value = inputValue;
  }

  render() {
    const {
      oneItem,
      searchBarItems,
      activeSearchType,
      searchbarType,
    } = this.props;

    return (
      <section>
        <FontAwesomeIcon icon={searchbarType === 'homeSearchbar' ? faSearchSolid : faSearchRegular} />
        <input
          type="text"
          className={Styles.input}
          onChange={(e) => this.typeSearch(e.target.value)}
          onKeyDown={this.onKeyEvent}
          onFocus={this.onInputFocus}
          ref={this.input}
          disabled={Boolean(activeSearchType === BASIC_SEARCH && oneItem && searchBarItems.length)}
          placeholder={this.initialPlaceholder}
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  searchBarItems: state.searchReducers[
    searchTypeListMapping[state.searchReducers.activeSearchType]
  ],
  activeSearchType: state.searchReducers.activeSearchType,
  currentTab: state.searchReducers.activeTab,
  keyword: state.searchReducers.userKeywords,
  tabs: state.searchReducers.tabs,
  storyFilters: state.storyFilters,
  activeWatchlist: state.watchlistReducer.activeWatchlist,
  token: state.watchlistReducer.userToken,
  watchlistCreationBegin: state.watchlistReducer.watchlistCreationBegin,
  dropDownOpen: state.watchlistReducer.newWatchlistDropDown,
  userQuerySearch: state.searchReducers.userQuerySearch,
  searchBarActive: state.searchReducers.searchBarActive,
  newWatchlistTopics: state.searchReducers[
    searchTypeListWatchlistMapping[state.searchReducers.activeSearchType]
  ],
  activeWatchlistTopics: state.watchlistReducer.activeWatchlistTopics,
  maxWatchlistSize: state.subscriptions.permissions.max_watchlist_size,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...searchActions, ...watchlistActions }, dispatch),
});

export default withComponentName(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
  )(SearchbarInput),
);
